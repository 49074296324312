[class^="ps-icon-"]:before,
[class*=" ps-icon-"]:before {
  font-family: "ps-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='ps-icon-'] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.ps-icon-refresh:before {
  content: "\49";
}
.ps-icon-close:before {
  content: "\69";
}
.ps-icon-home:before {
  content: "\6e";
}
.ps-icon-calendar:before {
  content: "\64";
}
.ps-icon-hamburger:before {
  content: "\72";
}
.ps-icon-left:before {
  content: "\71";
}
.ps-icon-right:before {
  content: "\4b";
}
.ps-icon-up:before {
  content: "\6b";
}
.ps-icon-search:before {
  content: "\74";
}
.ps-icon-setting:before {
  content: "\7a";
}
.ps-icon-game:before {
  content: "\41";
}
.ps-icon-filter:before {
  content: "\70";
}
.ps-icon-favorite-filled:before {
  content: "\6f";
}
.ps-icon-processing:before {
  content: "\42";
}
.ps-icon-shop:before {
  content: "\46";
}
.ps-icon-logout:before {
  content: "\76";
}
.ps-icon-promotion:before {
  content: "\47";
}
.ps-icon-bonus:before {
  content: "\4c";
}
.ps-icon-deposit:before {
  content: "\63";
}
.ps-icon-email1:before {
  content: "\6a";
}
.ps-icon-email2:before {
  content: "\6d";
}
.ps-icon-facebook:before {
  content: "\75";
}
.ps-icon-caution:before {
  content: "\65";
}
.ps-icon-caution-filled:before {
  content: "\66";
}
.ps-icon-check-filled:before {
  content: "\4a";
}
.ps-icon-check-stroke:before {
  content: "\68";
}
.ps-icon-info:before {
  content: "\73";
}
.ps-icon-problem:before {
  content: "\77";
}
.ps-icon-question:before {
  content: "\79";
}
.ps-icon-pause:before {
  content: "\43";
}
.ps-icon-play:before {
  content: "\48";
}
.ps-icon-line:before {
  content: "\6c";
}
.ps-icon-favorite:before {
  content: "\4e";
}
.ps-icon-loyalty:before {
  content: "\50";
}
.ps-icon-capture-screen:before {
  content: "\51";
}
.ps-icon-check-only:before {
  content: "\67";
}
.ps-icon-protect:before {
  content: "\78";
}
.ps-icon-friends:before {
  content: "\4f";
}
.ps-icon-copy:before {
  content: "\52";
}
.ps-icon-clock:before {
  content: "\53";
}
.ps-icon-ticket:before {
  content: "\54";
}
.ps-icon-quest:before {
  content: "\55";
}
.ps-icon-news:before {
  content: "\57";
}
.ps-icon-down:before {
  content: "\61";
}
.ps-icon-reward:before {
  content: "\56";
}
.ps-icon-add:before {
  content: "\58";
}
.ps-icon-edit:before {
  content: "\59";
}
.ps-icon-cross-filled:before {
  content: "\5a";
}
.ps-icon-pencil:before {
  content: "\30";
}
.ps-icon-pin-map:before {
  content: "\31";
}
.ps-icon-unlink:before {
  content: "\32";
}
.ps-icon-star:before {
  content: "\33";
}
.ps-icon-star-filled:before {
  content: "\34";
}
.ps-icon-close-circle:before {
  content: "\35";
}
.ps-icon-withdraw:before {
  content: "\44";
}
.ps-icon-wallet:before {
  content: "\45";
}
.ps-icon-award:before {
  content: "\62";
}
.ps-icon-back:before {
  content: "\4d";
}
.ps-icon-outline-transfer-2:before {
  content: "\36";
}
.ps-icon-allgame:before {
  content: "\37";
}
.ps-icon-pigshop:before {
  content: "\38";
}
.ps-icon-home-1:before {
  content: "\39";
}
.ps-icon-quest-1:before {
  content: "\21";
}
.ps-icon-promotion-1:before {
  content: "\22";
}
.ps-icon-outline-add-1:before {
  content: "\23";
}
