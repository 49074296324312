
@include block("chatroom-button") {
  font-weight: 500;
  min-width: 70px;
  display: flex;
  justify-content: flex-end;
}
@include block("chatroom-popover") {
  .ant-popover-inner {
    padding: 0;
  }
}
@include block("chatroom-selector") {
  min-width: 70px;
  margin: 0px !important;
  padding: 0px 12px 0px 0px !important;
  display: flex;
  justify-content: flex-end;
  font-family: inherit;
  border-radius: 0px !important;
  width: 100% !important;
  @include modifier("selected") {
    background-color: #F8F3FE;;
    border-right: 4px solid $--color-primary-1;
    padding: 0px 8px 0px 12px !important;
  }
  @include element("item") {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  @include element("text") {
    display: block;
    font-size: 20px;
    min-width: 10ch;
    text-align: right;
  }
}
@include block("chatbox-backdrop") {
  position: fixed;
  top: 64px;
  left: 0;
  width: 100%;
  bottom: 0;
  overflow: hidden;
  z-index: 80;
  background-color: transparent;
}
@include block("chatbox-motion") {
  position: fixed;
  top: 64px;
  right: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  z-index: 81;
  background-color: transparent;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.15);
  @include media-breakpoint-up(md) {
    width: 350px;
  }
}
@include block("chatbox") {
  display: flex;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $--color-greyscale-100;
  z-index: 81;

  @include modifier("read-only") {
    .pn-msg__actions {
      visibility: hidden;
    }
    .pn-msg-input__icons {
      pointer-events: none;
    }
    .ps-chat-message__emoji-button {
      visibility: hidden !important;
    }
    .ps-chat-message__reactions {
      pointer-events: none;
    }
  }
  @include element("loader") {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(201, 201, 201, 0.77);
    z-index: 20;
  }
  @include element("image") {
    content-visibility: visible;
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
}
@include block("chat-tac-modal") {
  padding: 0px;
  user-select: none;
  pointer-events: none;
  ol {
    padding: 16px;
    margin-left: 24px;
  }
  .ant-divider {
    margin: 12px 0;
  }
}
@include block("header-chatbox") {
  z-index: 30;
}
@include block("chatbox-member") {
  padding-top: 12px;
  max-height: 70vh;
  overflow: hidden auto;
}
@include block("focus-to-fix-annoying-bug") {
  &:focus {
    border: 2px solid rgb(0, 255, 0);
  }
}
@include block("emoji-picker") {
  position: absolute;
  top: 10%;
  right: 10%;
  z-index: 30 !important;
  @include modifier("fliped") {
    transform: translateY(-100%);
  }
}
@include block("gif-picker") {
  position: absolute;
  bottom: 58px;
  z-index: 30 !important;
}


// ================= CHAT BOX V2 ===================
@include block("chat-message-scroller") {
  position: relative;
  overflow: hidden scroll ;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  overflow-anchor: none;
  ::-webkit-scrollbar {
    width: '2px';
  }
  &::-webkit-scrollbar {
    width: '2px';
  }
}
@include block("chat-message-inner-scroller") {
  position: relative;
  bottom: 0px;
  right: 0px;
  left: 0px;
  top: 0px;
  overflow-anchor: none;
}
@include block("chat-message-list") {
  position: absolute;
  bottom: 0px;
  transform-origin: bottom;
  width: 100%;
  padding-top: 42px;
  padding-bottom: 74px;

  overflow-anchor: none;
}
@include block("chat-message") {
  position: relative;
  display: flex;
  width: calc(100%);
  padding: 0 12px;
  margin: 12px 0 0 0;
  &:hover {
    @include element("bubble") {
      background-color: #F8F3FE;
    }
    @include element("announcement") {
      background-color: #F8F3FE;
    }
    @include element("emoji-button") {
      visibility: visible;
      opacity: 1;
    }
  }   
  @include element('author') {
    max-width: 16ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 24px;
    color: #7F7F82;
    font-size: 20px;
    font-weight: 400;
    @include modifier('announcement') {
      font-weight: 500;
      color: #6729FF;
    }
  }
  @include element('time') {
    line-height: 24px;
    color: #AAAAAB;
    font-size: 16px;
    font-weight: 400;
    padding: 0 0 0 10px;
  }
  @include element("bubble") {
    $--bubble-color: #ede7fb;
    max-width: 80%;
    margin: 4px 8px 0px;
    border-radius: 4px;
    background-color: $--bubble-color;
    transition: 0.2s;

    @include modifier("cont") {
      margin: 0px 8px;
    }
    @include modifier("media") {
      min-height: 200px;
    }
    @include modifier("announcement") {
      background-color: transparent !important;
      padding-left: 0;
      padding-right: 0;
    }
    @include element("content") {
      padding: 0px 8px 4px;
    }
    @include element("info") {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      align-content: center;
      user-select: none;
      padding: 0 4px;
      width: 100%;
    }
    @include element("text") {
      line-height: 20px;
      color: #2A2A2E;
      font-size: 20px;
      font-weight: 400;
      user-select: none;
      word-break: break-word;
    }
    @include element("image") {
      content-visibility: visible;
      object-fit: cover;
      margin-bottom: 4px;
      width: 200px;
      height: 200px;
    }
  }
  @include element("emoji-button") {
    position: absolute;
    top: 8px;
    right: 8px;
    color: #999999;
    width: 36px;
    height: 22px;
    border-radius: 16px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    transition: 0.2s;
    opacity: 0;
    background-color: #fcfcfc;
    box-shadow: 0px 0px 2px #00000038;
    border: 1px solid rgb(211, 211, 211);
    border: none;
  }
  @include element("gif"){
    max-width: 200px;
    max-height: 200px;
  }
  @include element("announcement") {
    $--icon-size: 32px;
    $--padding-size: 0.5rem;
    color: #2A2A2E;
    position: relative;
    padding: $--padding-size;
    width: 100%;
    max-width: 300px;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    background-color: #ede7fb;
    border-radius: 8px;
    user-select: none;
    @include modifier("bigwin") {
      margin-top: 1.5rem;
    }
    @include element("bigwin-content") {
      position: relative;
      padding-top: 32px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      z-index: 1;
    }
    @include element("bigwin-game-icon") {
      position: absolute;
      display: inline;
      width: auto;
      height: min-content;
      top: 0px;
      left: 0px;
      transform: translateY(calc(($--icon-size + $--padding-size) * -1));
      cursor: pointer;
    }
    @include element("champion-button") {
      position: absolute;
      top: -4px;
      right: -4px;
      cursor: pointer;
    }
    @include element("bigwin-name") {
      width: 100%;
      font-weight: 900;
      font-size: 20px;
      line-height: 20px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      word-wrap: break-word;
      word-break: break-word;
      white-space: nowrap;
      b {
        color: $--color-primary-1;
      }
    }
    @include element("bigwin-game") {
      width: 100%;
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      b {
        color: $--color-blue-200;
        cursor: pointer;
      }
      span {
        color: $--color-primary-1;
        font-weight: 900;
      }
    }
    @include element("bigwin-amount") {
      width: 100%;
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
    }
    @include element("bigwin-effect") {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      overflow: hidden;
      border-radius: 8px;
      z-index: 0;
      > * {
        position: absolute;
        transform: translateY(-60px);
        width: 100%;
      }
      @include modifier("jackpot") {
        background-color: gold;
        box-shadow: 0 0 12px gold;
        animation: golden 3s infinite ease-out;
        @keyframes golden {
          0%, 100% {
            box-shadow: 0 0 12px gold;
          }
          50% {
            box-shadow: 0 0 0px gold;
          }
        }
      }
    }
  }
  @include modifier("grey") {
    @include element("bubble") {
      background-color: #F1F1F1;
    }
  }
  @include modifier("own") {
    flex-direction: row-reverse;
    @include element("bubble") {
      $--bubble-own-color: #c5ebd2;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-content: flex-end;
      align-items: flex-end;
      background-color: $--bubble-own-color !important;
      @include element("info") {
        flex-direction: row-reverse;
      }
      @include modifier("announce") {
        background: transparent;
      }
      @include element("text") {
        text-align: right;
      }
    }
    @include element("emoji-button") {
      left: 8px;
      right: auto;
    }
    &:hover {
      @include element("bubble") {
        background-color: #c5ebd2;
      }
    }
  }
}
@include block("chat-input") {
  display: flex;
  position: absolute;
  align-items: center;
  align-content: center;
  bottom: 0px;
  width: 100%;
  min-height: 58px;
  padding: 4px 8px;
  background-color: #f0f3f7;
  z-index: 25;
  
  @include element("icon-tool") {
    display: block;
    position: relative;
    display: flex;
    padding: 16px 0px;
    width: 50px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #999999;
    overflow: hidden;
    @include modifier('inside') {
      position: absolute;
      right: 10px;
      width: 32px;
    }
  }
  @include element("emoji"){
  }
  @include element("file-input"){
    position: absolute;
    top: 0%;
    left: 0%;
    width: 1px;
    height: 1px;
  }
  @include element("text"){
    width: 100%;
    display: flex;
    position: relative;
    padding: 0 8px;
    align-items: center;
    @include element("text-input"){
      width: 100%;
      padding: 6px 8px;

      font-size: 24px;
      font-weight: 400;
      line-height: 24px;
      white-space: wrap;

      overflow: hidden;
      background-color: #e4e9f0;
      color: #585858;
      border-radius: 20px;
      border: none !important;
      outline: none !important;
      box-sizing: border-box;
      resize: none;
    }
  }
  @include element("send-icon"){
  }
}
@include block("chat-guildname") {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: $--color-greyscale-100;
  user-select: none;
  z-index: 20;
  span {
    display: block;
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $--color-violet-200;
    text-align: center;
    margin: auto;
  }
}