/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {

  @include media-breakpoint-up("md") {
    width: 328px !important;
  }
}

/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--top-left {
}
.Toastify__toast-container--top-center {
  top: 72px !important;
}
.Toastify__toast-container--top-right {
}
.Toastify__toast-container--bottom-left {
}
.Toastify__toast-container--bottom-center {
}
.Toastify__toast-container--bottom-right {
  bottom: 60px !important;
  @include media-breakpoint-up("md") {
    right: 10px !important;
  }
}

/** Classes for the displayed toast **/
.Toastify__toast {
  border: rem(2px) solid !important;
  border-radius: $--border-radius-1 !important;
  box-shadow: none !important;
  margin: rem(8px) !important;
  font-size: rem(20px) !important;
  color: $--color-greyscale-600 !important;
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  max-height: 800px;
  line-height: 20px;
  overflow: hidden;
  cursor: pointer;
  direction: ltr;
}
.Toastify__toast--rtl {
}
.Toastify__toast--dark {
}
.Toastify__toast--default {
}
.Toastify__toast--info {
  border-color: $--color-lightblue-200 !important;
  background-color: $--color-lightblue-100 !important;
}
.Toastify__toast--success {
  border-color: $--color-green-200 !important;
  background-color: $--color-green-100 !important;
}
.Toastify__toast--warning {
  border-color: $--yellow-200 !important;
  background-color: $--yellow-100 !important;
}
.Toastify__toast--error {
  border-color: $--color-red-200 !important;
  background-color: $--color-red-100 !important;
}
.Toastify__toast-body {
  padding: 0 rem(16px) !important;
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
  color: $--color-greyscale-600 !important;
  align-self: center !important;
  opacity: 1 !important;
  pointer-events: auto; 
  padding: 0 rem(8px) !important;
}
.Toastify__close-button--default {
}
.Toastify__close-button > svg {
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
}

/** Classes for the progress bar **/
.Toastify__progress-bar {
  height: 0%;
}
.Toastify__progress-bar--animated {
}
.Toastify__progress-bar--controlled {
}
.Toastify__progress-bar--rtl {
}
.Toastify__progress-bar--default {
}
.Toastify__progress-bar--dark {
}

@keyframes toastEnter {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes toastExit {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.toast-fade-in {
  animation: toastEnter 0.3s ease-out both;
}

.toast-fade-out {
  animation: toastExit 0.3s ease-out both;
}

.toast-mobile-fade-in {
  animation: toastEnter 0.3s ease-out both;
}

.toast-mobile-fade-out {
  animation: toastExit 0.3s ease-out both;
}
.toast-info-icon {
  color: $--color-lightblue-200;
}

.toast-icon {
  display: flex;
  align-items: center;
  font-size: rem(24px);
  -webkit-text-stroke: 0.5px;
}

.toast-check-icon {
  color: $--color-green-200;
}

.toast-cross-icon {
  color: $--color-red-200;
}

.toast-caution-icon {
  color: $--yellow-200;
}

.toast__system-status {
  line-height: rem(20px);
}