@include block("game-section") {
  @include modifier('glowing') {
    .ps-game-section__header {
      margin-bottom: rem(12px);
    }
  }

  @include element("header") {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: rem(44px);
    margin-bottom: rem(8px);

    @include media-breakpoint-up(md) {
      height: rem(50px);
    }
  }

  @include element("title") {
    margin-bottom: 0;
    color: $--color-greyscale-600;
    line-height: 1;
    font-weight: 900;
    font-size: $--font-size-h4-mobile;
    @include media-breakpoint-up(md) {
      font-size: $--font-size-h4-desktop;
    }
  }
  @include element("sub-title") {
    font-weight: 400;
    font-size: 26px;
    line-height: 26px;
    color: $--color-greyscale-600;
  }
  @include element("icon") {
    font-size: 14px;
  }
  @include element("link") {
    display: inline-flex;
    align-items: center;

    > .ps-icon-up {
      transform: rotate(90deg);
    }
  }

  @include element("link-text") {
    line-height: 1;
    text-decoration: none;
    text-underline-offset: 1px;
    text-decoration-skip-ink: none;
    text-decoration-thickness: 1px;
  }

  @include element("wrapper") {
    overflow: hidden;
    margin: 0 auto 16px;
  }

  @include element("card") {
	padding-left: rem(8px) !important;
	padding-right: rem(8px) !important;
  }

  @include element("divider") {
    margin-top: 0;
    margin-bottom: 0;
    background-color: $--color-greyscale-200;
    opacity: 1;
  }

  @include block("game-card-mini"){
    width: min-content;
    cursor: pointer;

    @include element("img-container") {
      width: rem(75px);
      height: rem(75px);
    }

    @include element("title"){
      font-size: rem(20px);
      font-weight: 500;
      line-height: rem(24px);
      width: rem(75px);
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    @include block('rtp'){
      display: flex;
      align-items: center;

      @include element('dot'){
        position: relative;
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 100%;
        margin: 4px;
        background-color: $--color-blue-200;
        // animation: rtpDot 10s 10 forwards linear; 
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);

        &.active {
          animation: pulse 2s infinite;
        }
        @keyframes pulse {
          0% {
            transform: scale(1);
            box-shadow: 0 0 0 0 #0574f3ed;;
          }
        
          70% {
            transform: scale(1.05);
            box-shadow: 0 0 0 4px #0574f300;;
          }
        
          100% {
            transform: scale(1);
            box-shadow: 0 0 0 0 #0574f300;;
          }
        }
      }
      @include modifier('hot'){
        @include element('dot'){
          background-color: #E73812 !important;
          &.active {
            animation: hot-pulse 2s infinite;
          }
          @keyframes hot-pulse {
            0% {
              transform: scale(0.95);
              box-shadow: 0 0 0 0 #E73812;;
            }
          
            70% {
              transform: scale(1);
              box-shadow: 0 0 0 4px #E7381200;;
            }
          
            100% {
              transform: scale(0.95);
              box-shadow: 0 0 0 0 #E7381200;;
            }
          }
        }
        @include element('bar'){
          background-color: #E73812 !important;
        }
      }
      @include element('bar'){
        display: inline-block;
        width: 9ch;
        font-size: 16px;
        color: $--color-greyscale-100;
        background-color: $--color-blue-200;
        font-weight: 400;
        border-radius: 40px;
        line-height: 2.5ch;
        text-align: center;
      }
    }
  }
}
