@include block("leaderboard-section") {
  margin-bottom: rem(16px);

  @include media-breakpoint-up(md) {
    margin-bottom: rem(32px);
  }

  @include element("wrapper") {
    background-color: $--color-violet-100;

    @include media-breakpoint-up(lg) {
      background-color: transparent;
    }
  }

  @include element("content") {
    background-color: transparent;

    @include media-breakpoint-up(lg) {
      padding-right: rem(16px);
      padding-left: rem(16px);
      background-color: $--color-violet-100;
    }
  }

  @include element("line") {
    margin: 0;
    opacity: 1;
    background-color: $--color-greyscale-200;
  }
}
