#fc_frame {
  visibility: hidden;
  z-index: 12 !important;
  bottom: 80px !important;
  @include media-breakpoint-up('lg'){
    bottom: 20px !important;
  }
  &.fc-open {
    z-index: 1001 !important;
  }
  &.active {
    visibility: visible;
  }
}