@include block("rain-queue") {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
  user-select: none;
  z-index: 22;
  overflow: hidden;
}
@include block("rain-card-motion") {
  position: absolute;
  z-index: 13;
  &:first-child {
    z-index: 14;
    .ps-rain-card {
      top: 12px;
      transform: scale(1);
    }
  }
  .ps-rain-card {
    top: 6px;
    transform: scale(0.98);
  }
}
@include block("tip-rain-modal") {
  margin-bottom: -20px;
  @include element("title") {
    width: 100%;
    text-align: center;
    font-weight: 900;
    font-size: 24px;
    line-height: 24px;
    color: #2A2A2E;
    margin-bottom: 16px;
  }
  @include element("form") {
    display: grid;
    grid-template-columns: 10ch auto;
    align-items: start;
    justify-items: stretch;
    justify-content: start;
    width: fit-content;
    margin: auto;

    @include element("form-item") {
      @include modifier("error") {
        input {
          border: 2px solid red;
        }
        span {
          color: red;
        }
      }
    }
    @include element("label") {
      display: inline-block;
      padding-top: 1ch;
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      color: #8C8C8C;
    }
    @include element("name") {
      display: inline-block;
      padding-top: 1ch;
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      color: #2A2A2E;
      max-width: 21ch;
    }
    > div {
      padding: 4px;
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      color: #2A2A2E;
      input {
        border: 2px solid #EAEAEA;
      }
      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #6B6B6B;
      }
    }
  }
  @include element("button") {
    margin: 16px auto 0;
    min-width: 18ch;
  }
}