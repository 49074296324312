@include block("tac") {
  height: 90vh;
  border-radius: 4px;
  padding: rem(16px);
  background-color: $--color-greyscale-100;

  @include element("header") {
    display: flex;
    align-items: center;
    height: rem(24px);
    margin-bottom: rem(16px);
    color: $--color-greyscale-600;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1;

    .ps-button__body {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ps-icon-close::before {
      display: block;
      color: $--color-greyscale-600;
      font-size: rem(12px);
    }
  }

  @include element("body") {
    height: calc(100% - #{rem(74px)});
    margin-top: rem(16px);
    overflow-y: scroll;
  }

  @include element("line") {
    width: calc(100% - #{rem(16px)});
    height: 1px;
    background-color: $--color-greyscale-400;
  }

  @include element("content") {
    width: calc(100% - #{rem(16px)});
    margin-right: rem(16px);

    strong,
    p,
    br {
      font-size: rem(20px);
      line-height: 1;
    }

    strong {
      font-weight: bold;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
