$--input-space-left: 16px;
$--input-space-right: 28px;

@include block('input') {
    border: 2px solid $--color-greyscale-400;
    border-radius: 4px;
    min-width: 117px;
    height: 44px;
    text-indent: $--input-space-left;
    padding-left: 0;
    font-size: 24px;
    outline: 0 none;
    z-index: 0;

    &[disabled] {
        background: $--color-greyscale-200 !important;
        border: 2px solid $--color-greyscale-200 !important;
        color: $--color-greyscale-400 !important;
    }

    @include modifier('textarea') {
        text-indent: 0 !important;
        padding: 3px $--input-space-left 0 $--input-space-left !important;
        word-break: break-word;
        margin-bottom: rem(-8px);
    }
    
    @include modifier('show-error-icon') {
        padding-right: $--input-space-right;
    }

    @include modifier('bank-4-digit') {
        width: 117px;
        margin-right: 8px;
        text-indent: 62px;
        padding-right: 14px;

        & + .ps-input--text {
            width: calc(100% - (117px + 8px));
        }
    }

    @include modifier('bank-6-digit') {
        width: 143px;
        margin-right: 8px;
        text-indent: 62px;
        padding-right: 14px;

        & + .ps-input--text {
            width: calc(100% - (143px + 8px));
        }
    }

    @include modifier('phone-extension') {
      min-width: 87px;
      width: 90px;
      text-indent: 40px;
      padding-right: 14px;
      background: transparent;
      touch-action: none;
      pointer-events: none;
      border-radius: 4px 0 0 4px;

      & + .ps-input--text {
          width: calc(100% - (79px + 8px));
          border-radius: 0 4px 4px 0;
          margin-left: -4px;
      }
  }

    @include modifier('text') {
        width: 100%;
    }

    @include modifier('error') {
        border-color: $--color-red-200 !important;
        caret-color: $--color-red-200;
    }
}

@include block('input-layout') {
    max-height: 92px !important;
    position: relative;
    font-size: 20px;
}

@include block('input-title') {
    line-height: 20px;
    word-break: break-word;
    font-family: inherit;
    margin-bottom: 4px;
    color: $--color-greyscale-400;
}

@include block('input-error-layout') {
    display: flex;
    margin: 4px 0;
    height: 20px;
    font-family: inherit;
}

@include block('input-message') {
    color: $--color-red-200;
    word-break: break-word;
    line-height: 20px;
}

@include block('input-icon-caution-outline') {
    font-size: 15px;
    @include modifier('error') {
        color: $--color-red-200;
        margin-right: 4px;
    }
}

@include block('input-icon-caution-filled') {
    font-size: 16px;
    position: absolute;
    margin-left: $--input-space-right * -1;
    margin-top: 14px;
    @include modifier('error') {
        color: $--color-red-200;
    }
}

@include block('input-bank-icon') {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 6px 16px;
    border-radius: 4px;
    overflow: hidden;
}

@include block('input-phone-icon') {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 24px;
  height: 17px;
  margin: 14px 10px;
  overflow: hidden;
  z-index: 15;
}
