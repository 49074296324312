@include block("register-with-phone") {
  margin-top: rem(40px);

  @include element("already-member") {
    height: rem(20px);
    margin: rem(16px) auto rem(48px) auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
