@include block("contact-link") {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  height: auto;
  padding: rem(16px 8px 16px 16px);
  user-select: none;
  background-color: #f8f8f8;
  border-radius: 4px;
  border: 2px solid $--color-greyscale-200;

  @media (max-width: 374.98px) {
    padding: rem(8px);
  }

  > i:first-child {
    font-size: rem(48px);
  }

  > i:last-child {
    width: rem(32px);
    height: rem(32px);
    margin-left: auto;
    color: $--color-greyscale-600;
    font-size: rem(15px);
    transform: rotate(-90deg);
  }
  @include element('left-container') {
    display: flex;
  }
  @include element('right-container') {
    color: $--color-greyscale-400;
    font-size: 16px;
    display: flex;
    align-items: center;
  }

  @include element("icon") {
    width: rem(48px);
    height: rem(48px);
    margin-right: 16px;
    @media (max-width: 374.98px) {
      width: rem(40px);
      height: rem(40px);
    }
  }

  @include element("content") {
    display: flex;
    flex-direction: column;
    margin-right: 8px;
  }

  @include element("title") {
    color: $--color-greyscale-600;
    font-size: rem(24px);
    font-weight: normal;
    line-height: 1;

    @media (max-width: 374.98px) {
      font-size: rem(20px);
    }
  }

  @include element("subtitle") {
    color: $--color-greyscale-600;
    font-size: rem(20px);
    font-weight: 300;
    line-height: 1;
    text-align: left;

    @media (max-width: 374.98px) {
      font-size: rem(18px);
    }
  }
}
