@include block('banner-game-section') {
  position: relative;
  font-size: 0px;
  .slick-dots {
    bottom: 20px;
    display: flex !important;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
    pointer-events: none;
  }
}

@include block('banner-carousel') {
  width: 100%;
  position: relative;
  @include element("top-layer") {
    border-radius: rem(8px);
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    width: 100%;
    z-index: 1;
    @include media-breakpoint-up(md) {
      width: 50%;
    }
  }
  @include modifier('layout') {
    width: inherit;
    min-height: 325px;
    position: relative;
    display: flex;
    align-items: flex-end;

    @include media-breakpoint-up(md) {
      min-height: 620px;
    }
    @include media-breakpoint-up(lg) {
      min-height: 390px;
    }
    @include media-breakpoint-up(xl) {
      min-height: 490px;
    }
    @media only screen and (min-width: 1920px) {
      min-height: 690px;
    }
  }

  @include element('image') {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    object-fit: cover !important;
  }

  @include element('item') {
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translate(-50%);
    bottom: rem(64px);
    @include media-breakpoint-up(lg) {
      bottom: rem(32px);
    }
    h2,
    h1 {
      text-align: center;
      color: $--color-greyscale-100;
      overflow: hidden;
      word-wrap: break-word;
      line-height: rem(50px);
      max-height: rem(100px);
      @include media-breakpoint-up(lg) {
        line-height: rem(136px);
        max-height: rem(272px);
      }
      margin: 0;
    }

    @include modifier('text') {
      min-width: 160px;
      height: rem(52px);
      margin: auto;
      line-height: rem(20px);
      text-decoration: none;
      color: $--color-greyscale-100;
      .ps-text-link__body {
        text-decoration-line: none;
      }
    }
  }
}

@include block('banner-carousel-track-dots-layout') {
  bottom: '35%';
}

.ant-carousel .slick-dots {
  @include block('banner-carousel-track-dots') {
    display: flex;
    align-items: flex-end;
    max-height: 16px;
    margin: 0;
    padding: 0;
    li {
      height: 16px;
      width: rem(32px);
      margin: 0;
      padding: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      * { pointer-events: all }
      &.slick-active {
        :first-child {
          background-color: $--color-greyscale-100;
        }
      }
    }
  }
}

@include block('banner-carousel-dot') {
  width: 8px;
  height: 8px;
  margin: 0;
  padding: 0;
  border: 1px solid $--color-greyscale-100;
  border-radius: 50%;
}

@include block('banner-arrow-layout') {
  top: calc((100% - 100px) / 2);
  left: 50%;
  transform: translate(-50%);
  display: flex;
  justify-content: space-between;
  z-index: 20;
}
@include block('banner-arrow') {
  cursor: pointer;
  width: rem(48px);
  height: rem(48px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: $--color-greyscale-100;
  font-size: rem(18px);
  @include media-breakpoint-up(md) {
    font-size: rem(32px);
  }
}
@include block('banner-carousel-text'){
  position: absolute;
  bottom: 32px;
  left: 50%;
  pointer-events: all;
  color: $--color-greyscale-100 !important;
}

// style for new React Swiper
.swiper-button-next, 
.swiper-button-prev {
  color: $--color-greyscale-100 !important;
  &::after {
    font-size: rem(28px) !important;
    @include media-breakpoint-up(md) {
      font-size: rem(32px);
    }
  }
}