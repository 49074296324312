@include block('user-avatar') {
  display: inline-block;
  position: relative;
  padding: 0px;
  width: auto;
  font-size: 0px;
  line-height: 0px;
  @include element('avatar') {
    position: relative;
  }
  @include element('vip') {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: calc(100% - 6px);
    left: 50%;
    transform: translateX(-50%);
    min-width: 50px;
    z-index: 13;
  }
  @include element('guild') {
    position: absolute;
    top: 100%;
    right: -1px;
    width: 28px;
    height: 28px;
    object-fit: contain;
    object-position: right;
    transform: translateY(-50%);
    z-index: 12;
  }
  @include element('close-short-profile') {
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    margin: 2px;
    background-color: #ffffffb3;
    backdrop-filter: blur(2px);
    z-index: 12;
    border-radius: 100%;
    overflow: hidden;
    font-size: 20px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
  }
}
@include block('user-tier') {
  display: flex;
  flex-direction: row;
  gap: 8px;
  user-select: none;
}
@include block('user-badge') {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  width: 30px;
  height: 16px;
  padding: 1px 8px;
  margin-bottom: 2px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  user-select: none;
  z-index: 20;
  @include modifier('none') {
    background: #FFFFFF;
  }
  @include modifier('bronze') {
    background: $--vip-bronze
  }
  @include modifier('silver') {
    background: $--vip-silver
  }
  @include modifier('gold') {
    background: $--vip-gold
  }
  @include modifier('diamond') {
    background: $--vip-diamond
  }
}
@include block('user-star') {
  position: relative;
  width: 100%;
  height: 16px;
  max-width: calc((16px * 5) + 16px);
  user-select: none;
  img {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
}
@include block("action-menu") {
  background-color: #F0EAFF !important;
  border: 2px solid white;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  width: min-content;

  li {
    background-color: transparent !important;
    color: #2A2A2E !important;
    width: min-content;
    min-width: 8ch;
  }
}
@include block("short-profile") {
  position: relative;
  width: 100%;
  
  .ps-user-star {
    max-width: 200px;
    width: 72px;
    margin-top: 2px;
  }
  @include element("avatar") {
    position: absolute;
    top: -56px;
    left: 50%;
    transform: translateX(-50%);
  }
  @include element("action-menu") {
    position: absolute;
    top: 0px;
    right: 0%;
    transform: translateX(0%);
  }
}