@include block('loading'){
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $--color-primary-1;
  color: $--color-primary-1;
  box-shadow: 9984px 0 0 0 $--color-primary-1, 9999px 0 0 0 $--color-primary-1, 10014px 0 0 0 $--color-primary-1;
  animation: dotTyping 1.5s infinite linear;
}

@keyframes dotTyping {
  0% {
    box-shadow: 9984px 0 0 0 $--color-primary-1, 9999px 0 0 0 $--color-primary-1, 10014px 0 0 0 $--color-primary-1;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 $--color-primary-1, 9999px 0 0 0 $--color-primary-1, 10014px 0 0 0 $--color-primary-1;
  }
  33.333% {
    box-shadow: 9984px 0 0 0 $--color-primary-1, 9999px 0 0 0 $--color-primary-1, 10014px 0 0 0 $--color-primary-1;
  }
  50% {
    box-shadow: 9984px 0 0 0 $--color-primary-1, 9999px -10px 0 0 $--color-primary-1, 10014px 0 0 0 $--color-primary-1;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 $--color-primary-1, 9999px 0 0 0 $--color-primary-1, 10014px 0 0 0 $--color-primary-1;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 $--color-primary-1, 9999px 0 0 0 $--color-primary-1, 10014px -10px 0 0 $--color-primary-1;
  }
  100% {
    box-shadow: 9984px 0 0 0 $--color-primary-1, 9999px 0 0 0 $--color-primary-1, 10014px 0 0 0 $--color-primary-1;
  }
}
