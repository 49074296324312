@use "sass:math";

@import "base/functions";
@import "abstracts/variables";
@import "abstracts/animation";
@import "abstracts/mixins";
@import "base/typographys";
@import "abstracts/icons";
@import "base/utilities";
@import "base/base";

/**
* 3rd patry library
*/
@import "vendors/index.scss";
@import "react-toastify/dist/ReactToastify.css";

@import "swiper/css";
@import "swiper/css/navigation";
@import "swiper/css/pagination";
@import "swiper/css/scrollbar";

/*components style*/
@import "components/index.scss";

/*pages style*/
@import "pages/index.scss";

