@import '~bootstrap/scss/variables';
/**
 * BEM
 */
$--bem-namespace: 'ps';
$--bem-element-separator: '__';
$--bem-modifier-separator: '--';
$--bem-state-prefix: 'is-';

$--color-violet-100: #EFE6FD;
$--color-violet-200: #D0B3FA;
$--color-violet-300: #914DF3;
$--color-violet-400: #6200EE;
$--color-violet-500: #4E00BE;
$--color-violet-600: #310077;

$--orange-100: #FFF4E6;
$--orange-200: #FFDEB3;
$--orange-300: #FFB24D;
$--orange-400: #FF9100;
$--orange-500: #CC7400;
$--orange-600: #804900;

$--color-greyscale-100: #FCFCFC;
$--color-greyscale-200: #D4D4D5;
$--color-greyscale-300: #AAAAAB;
$--color-greyscale-400: #7F7F82;
$--color-greyscale-500: #555558;
$--color-greyscale-600: #2A2A2E;

$--yellow-100: #FEF6E3;
$--yellow-200: #F6C244;

$--color-lightblue-100: #E6F7FC;
$--color-lightblue-200: #5BC8EC;

$--color-blue-100: #DAEAFD;
$--color-blue-200: #0573F3;

$--color-white: #FFFFFF;
$--color-black: $--color-greyscale-600;

$--color-red: #C8373E;

$--color-transparent: transparent;

$--color-red-100: #F7E1E2;
$--color-red-200: #C8373E;

$--color-green-100: #E2EDE6;
$--color-green-200: #408558;

$--color-primary-1: $--color-violet-400;
$--color-primary-1-faded: #F3ECFD;
$--color-primary-2: $--orange-400; // warning color
$--color-success: $--color-green-200; // success color
$--color-warning: $--yellow-200; // warning color
$--color-danger: $--color-red-200; // danger color

/**
 * Box Shadows
 */
$--box-shadow-1: 0 4px 12px 0 rgba(1, 27, 42, 0.14);
$--box-shadow-2: 0 2px 10px 0 rgba(1, 27, 42, 0.12);
$--box-shadow-3: 0 2px 8px 0 rgba(1, 27, 42, 0.1);
$--box-shadow-4: 0 1px 6px 0 rgba(1, 27, 42, 0.1);
$--box-shadow-5: 2px 2px 8px rgba(0, 0, 0, 0.2);

/**
 * Text Shadows
 */
$--text-shadow-1: 0 4px 12px rgba(1, 27, 42, 0.14);
$--text-shadow-2: 0 2px 10px rgba(1, 27, 42, 0.12);
$--text-shadow-3: 0 2px 8px rgba(1, 27, 42, 0.1);
$--text-shadow-4: 0 1px 6px rgba(1, 27, 42, 0.1);

/**
 * Font Sizes
 */
$--font-size-base: 24px;
$--font-size-base-desktop: 24px;
$--font-size-base-mobile: $--font-size-base;

// $--font-size-h1: 2.488rem;
// $--font-size-h2: 2.048rem;
// $--font-size-h3: 1.728rem;
// $--font-size-h4: 1.44rem;
// $--font-size-h5: 1.2rem;

$--font-size-h1: 1.4rem;
$--font-size-h2: 1.3rem;
$--font-size-h3: 1.1rem;
$--font-size-h4: 1rem;
$--font-size-h5: 1rem;

// $--font-size-h1-desktop: 5.653rem;
// $--font-size-h2-desktop: 3.998rem;
// $--font-size-h3-desktop: 1.827rem;
// $--font-size-h4-desktop: 1.999rem;
// $--font-size-h5-desktop: 1.414rem;

$--font-size-h1-desktop: 2rem;
$--font-size-h2-desktop: 1.5rem;
$--font-size-h3-desktop: 1.1rem;
$--font-size-h4-desktop: 1rem;
$--font-size-h5-desktop: 1rem;

$--font-size-h1-mobile: $--font-size-h1;
$--font-size-h2-mobile: $--font-size-h2;
$--font-size-h3-mobile: $--font-size-h3;
$--font-size-h4-mobile: $--font-size-h4;
$--font-size-h5-mobile: $--font-size-h5;

$--font-size-b1: $--font-size-base;
$--font-size-b2: $--font-size-b1 - 2px;
$--font-size-b3: $--font-size-b2 - 2px;

/**
 * VIP badge colors
 */
 
$--vip-bronze: linear-gradient(115.39deg, #B94520 21.73%, #8C3114 65.76%, #B94520 84.35%);
$--vip-silver: linear-gradient(148.67deg, #A8A8A8 23.45%, rgba(69, 69, 69, 0.95) 59.17%, #A8A8A8 81.08%);
$--vip-gold: linear-gradient(148.67deg, #EB8D00 23.45%, rgba(139, 84, 0, 0.95) 59.17%, #EB8D00 81.08%);
$--vip-diamond: linear-gradient(148.67deg, #491DB5 23.45%, rgba(43, 17, 107, 0.95) 59.17%, #491DB5 81.08%);

/**
 * Border Radius
 */
$--border-radius-min: 4px;
$--border-radius-1: 8px;
$--border-radius-2: 16px;
$--border-radius-3: 24px;
$--border-radius-4: 32px;
$--border-radius-5: 40px;
$--border-radius-h: 50%;

/**
 * Spacing
 */
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25
  ),
  2: (
    $spacer * 0.5
  ),
  3: $spacer,
  4: (
    $spacer * 1.5
  ),
  5: (
    $spacer * 2
  ),
  6: (
    $spacer * 2.5
  ),
  7: (
    $spacer * 3
  ),
  8: (
    $spacer * 3.5
  ),
  9: (
    $spacer * 4
  ),
  10: (
    $spacer * 4.5
  )
);

$--spacers-fix: (
  0: 0px,
  1: 4px,
  2: 8px,
  3: 16px,
  4: 24px,
  5: 32px,
  6: 40px,
  7: 48px,
  8: 56px,
  9: 64px,
  10: 72px
);

/**
 * Grid Gutter
 */
$grid-gutter-width: 16px;
$--grid-gutters: (0, 4, 8, 16);

/**
 * Breakpoints
 */
$grid-breakpoints: (
  ss: 0,
  xs: 320px,
  sm: 375px,
  md: 768px,
  lg: 1024px,
  xl: 1440px
);

$container-max-widths: (
  sm: 540px - $grid-gutter-width,
  md: 768px - $grid-gutter-width,
  lg: 992px,
  xl: 1076px
);

/**
 * z-index
 */
$z-index: (
  base: 0,
  header: 999,
  overlay: 1000,
);
