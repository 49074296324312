@include block('profile'){
  margin-top: rem(16px);
  margin-bottom: rem(16px);
  line-height: 1;

  @include element('container') {
    display: flex;
    flex-direction: column;
    margin: -16px;
    padding: 16px;
  }
  @include element('container-section') {
    animation: flashingContainer 0.3s 1s 2 alternate-reverse ease-in-out ;
    animation-play-state: paused;
    
    @keyframes flashingContainer {
      0% {
        background-color: $--color-violet-100;
      }
      100% {
        background-color: transparent;
      }
    }
  }

  @include element('section') {
    display: flex;
    justify-content: space-between;
  }

  @include element('title') {
    font-weight: 900;
    margin-bottom: 8px;
    display: flex;
    *:first-child {
      flex-grow: 1;
    }
  }

  @include element('subtitle') {
    font-size: 20px;
  }

  @include element('myprofile') {
    display: flex;
    *:first-child {
      flex: 1;
    }
  }

  @include element('value') {
    line-height: 1;
  }

  @include element("divider") {
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
    border-color: $--color-greyscale-200;
    opacity: 1;
  }

  @include element("button-edit") {
    color: $--color-blue-200;
    text-decoration: underline;
    font-weight: normal;
    font-size: rem(20px);
    cursor: pointer;
  }
  @include element("bonus-toggle-label") {
    font-size: 20px;
    margin-bottom: 4px;
    @include element("bonus-toggle-flag") {
      width: 10px;
      height: 10px;
      background-color: $--color-greyscale-200;
      border-radius: 10px;
      margin-left: 10px;
      margin-right: 10px;
    }
    @include modifier("active") {
      color: $--color-green-200;
      @include element("bonus-toggle-flag") {
        background-color: $--color-green-200;
      }
    }
  }
  @include element("bonus-toggle-timer") {
    margin-right: 10ch;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: $--color-greyscale-300;
  }
  @include element("bonus-toggle-switch") {
    &:checked {
      border-color: $--color-green-200 !important;
      background-color: $--color-green-200 !important;
    }
    &:focus {
        box-shadow: none !important;
    }
  }
}

@include block("bonus-toggle-information-content") {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: $--color-greyscale-600;
  b {
    display: block;
    font-weight: 900;
    font-size: 24px;
    line-height: 24px;
  }
  .ant-divider {
    margin: 12px 0 !important;
    border-top: 1px solid $--color-greyscale-600 !important;
  }
  span {
    display: block;
    font-weight: 900;
    font-size: 20px;
    line-height: 20px;
    margin: 1.3ch 0 0.5ch;
  }
  a {
    font-size: 16px;
  }
}

@include block('profile-header') {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;


  @include element('text') {
    margin-left: rem(16px);
    flex-grow: 1;
  }
  @include element('profile') {
    border-radius: rem(26px);
    width: 52px;
    height: 52px;
    overflow: hidden;
  }


  @include element('name') {
    font-weight: 900;
    font-size: rem(24px);
  }

  @include element('copy') {
    font-weight: 400;
    font-size: rem(16px);
  }

  @include element('tag-btn') {
    font-weight: 400;
    font-size: rem(16px);
    border-radius: 4px;
    width: 46px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: rem(5px);
  }
}
