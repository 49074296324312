@include block("article-card") {
  border: 1px solid #dedee0;
  border-radius: 4px;
  padding: 6px;
  max-width: 250px;
  cursor: pointer;

  @include element("category") {
    color: $gray-100;
    background-color: $--color-blue-200;
    font-size: rem(12px);
    line-height: 12px;
    border-radius: 2px;
    padding: 4px;
  }

  @include element("image") {
    margin: 0px -6px;
  }

  @include element("description") {
    color: $gray-500;
    font-size: rem(18px);
    line-height: 16px;
	margin-top: 6px;

	white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
