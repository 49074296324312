@include block('faq-component') {
  .ant-collapse-content-box {
    background-color: $--color-violet-100;
    padding: rem(10px) rem(15px) !important;
    @include media-breakpoint-up(lg) {
      padding: 8px 16px;}
  }
  .ant-collapse-item > .ant-collapse-header {
    background-color: white;
    border: 2px solid $--color-greyscale-200;
    line-height: 24px;
    padding: rem(12px) rem(37px) rem(12px) rem(15px);
    border-radius: rem(4px) !important;
    color: $--color-greyscale-600;
  }
  .ant-collapse-item-active > .ant-collapse-header {
    border: 2px solid $--color-violet-400;
    line-height: 24px;
    padding: rem(12px) rem(37px) rem(12px) rem(15px);
    border-radius: rem(4px) !important;
    color: $--color-greyscale-600;
  }
  @include element('arrow-icon') {
    transform-origin: center center;
    transition: transform .24s;
    font-size: 16px !important;
    height: rem(16px);
    top: 35% !important;
    @include modifier('toggle-down') {
      transform: rotate(180deg) !important;
    }
    @include modifier('toggle-up') {
      transform: rotate(0deg) !important;
    }
  }
  @include element('panel-title') {
    font-size: 24px;
    margin-bottom: 20px;
    border-bottom: 0;
  }
  @include element('panel-content') { 
    white-space: pre-wrap;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    font-size: 20px;
    border-radius: 0px 0px 4px 4px;
    color: $--color-greyscale-600;
    > ul {
      padding-left: rem(30px);
      margin-bottom: 0
   }
    
  }
  @include element('panel-content-li') { 
    > i {
      font-size: 12px;
    }
  }
}