@include block('reset-pin-step1'){
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include element('title'){
    font-weight: 900;
    @include media-breakpoint-up(md){
      margin-bottom: rem(32px);
      line-height: 1;
      font-size: rem(68px);
    }
  }
  @include element('subtitle'){
    font-size: rem(24px);
    font-weight: 900;
  }
  @include element('text'){
    font-size: rem(24px);
    line-height: 1;
    text-align: center;
  }
  @include element('last'){
    margin-bottom: rem(32px);
  }
  @include element('last-shot'){
    margin-bottom: rem(16px);
  }
  @include element('input'){
    width: 100% !important;
    margin-bottom: rem(16px);
    @include media-breakpoint-up(md){
      width: rem(344px) !important;
    }
  }
  @include element('btn'){
    margin-top: rem(24px);
  }
}
