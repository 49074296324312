@include block("animated-banner") {
  position: relative;
  display: flex;
  overflow: hidden;
  background-color: #00000001;
  width: calc(100% + 36px);
  padding-top: 69%;
  margin: 0 -18px 0.4em;
  @include media-breakpoint-up(md) {
    width: 100%;
    padding-top: 39%;
    margin: 0 0 0.4em;
  }
}
@include block("quests-banner") {
  aspect-ratio: 390;
  @include media-breakpoint-up(md) {
    aspect-ratio: 490;
  }
  @include element("info") {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: white;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    padding: 1em 0 1.2em;
    @include element("title") {
      width: 60%;
      color: $--color-greyscale-100;
      text-align: center;
      font-style: normal;
      font-weight: bold;
      font-size: 2.1rem;
      line-height: 50px;
    }
    @include element('redemption'){
      position: relative;
      width: 60%;
      display: flex;
      text-align: center;
      flex-wrap: wrap;
      justify-content: center;
      align-content: flex-end;
      padding-top: 2em;

      @include element('tickets'){
        width: 100%;
        color: $--color-greyscale-100;;
        display: flex;
        vertical-align: center;
        align-content: center;
        justify-content: center;
        font-weight: 400;
        margin-bottom: 0.5em;
        font-size: 25px;
        text-shadow: 0 1px 2px #00000035;;
        z-index: 11;
        
        > span {
          margin: 0 0.5ch;
          font-weight: 500;
        }
      }
      @include element('button'){
        width: 80%;
        padding: 0.5em;
      }
    } 
  }
  @include element("stage") {
    @include element("props"){
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      background-position: center;
    }
    @include element("ship"){
      background-image: url("/images/dailyquests/props/Quest-Banner-Mobile03.png");
      @include media-breakpoint-up(md) { background-image: url("/images/dailyquests/props/Quest-Banner-Desktop03.png"); }
    }
    // @include element("pig"){
    //   background-image: url("/images/dailyquests/props/Quest-Banner-Mobile03.png");
    //   @include media-breakpoint-up(md) { background-image: url("/images/dailyquests/props/Quest-Banner-Desktop03.png"); }
    // }
    @include element("screen"){
      $baseUrl: "/images/dailyquests/props/Quest-Banner-";
      $baseExt: ".png";

      background-image: url("/images/dailyquests/props/Quest-Banner-Mobile02.png");
      @include media-breakpoint-up(md) { background-image: url("/images/dailyquests/props/Quest-Banner-Desktop02.png"); }
      filter: brightness( 0.3) grayscale(0.8);
      animation: shipscreen0 0.5s 0.1s forwards;
      @keyframes shipscreen0 {
        0% {
          filter: grayscale(0.8) brightness( 0.5);
        }
        10% {
          filter: grayscale(0.3) brightness( 1.2);
        }
        20% {
          filter: grayscale(0) brightness( 0.5);
        }
        30% {
          filter: grayscale(0) brightness( 1);
        }
        35% {
          filter: grayscale(0) brightness( 0.3);
        }
        40% {
          filter: grayscale(1) brightness( 1);
        }
        100% {
          filter: brightness( 1);
        }
      }
      @include modifier('1'){
        animation-delay: 0.3s;
        background-image: url("/images/dailyquests/props/Quest-Banner-Mobile02.png");
        @include media-breakpoint-up(md) { background-image: url("/images/dailyquests/props/Quest-Banner-Desktop02.png"); }
      }
      @include modifier('2'){
        animation-delay: 0.4s;
        background-image: url("/images/dailyquests/props/Quest-Banner-Mobile04.png");
        @include media-breakpoint-up(md) { background-image: url("/images/dailyquests/props/Quest-Banner-Desktop04.png"); }
      }
      @include modifier('3'){
        animation-delay: 0.5s;
        background-image: url("/images/dailyquests/props/Quest-Banner-Mobile05.png");
        @include media-breakpoint-up(md) { background-image: url("/images/dailyquests/props/Quest-Banner-Desktop05.png"); }
      }
      @include modifier('4'){
        animation-delay: 1.1s;
        background-image: url("/images/dailyquests/props/Quest-Banner-Mobile06.png");
        @include media-breakpoint-up(md) { background-image: url("/images/dailyquests/props/Quest-Banner-Desktop06.png"); }
      }
      @include modifier('5'){
        animation-delay: 0.2s;
        background-image: url("/images/dailyquests/props/Quest-Banner-Mobile07.png");
        @include media-breakpoint-up(md) { background-image: url("/images/dailyquests/props/Quest-Banner-Desktop07.png"); }
      }
    }
    @include element("space"){
      background-image: url("/images/dailyquests/props/Quest-Banner-Mobile01.png");
      @include media-breakpoint-up(md) { background-image: url("/images/dailyquests/props/Quest-Banner-Desktop01.png"); }
      animation: warping 16s forwards ease-in-out infinite alternate;
      transform: scale(1);
      @keyframes warping {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(5);
        }
      }
    }
  }
}