@include block('footer') {
  display: grid;
  justify-content: center;
  background-color: $--color-violet-600;
  padding-top: rem(27px);
  flex-shrink: 0;
  @include media-breakpoint-down(lg) {
    padding-bottom: 80px;
  }

  @include element('row') {
    margin: auto;
    max-width: 100vw;
    padding: 0 18px;
    overflow: hidden;
    @include media-breakpoint-up('lg') { max-width: 1024px }
  }

  @include element('text') {
    font-size: $--font-size-b3;
    color: $--color-greyscale-100;
    user-select: none;

    @include modifier('link') {
      cursor: pointer;
      &:hover {
        color: $--color-primary-2;
      }
    }
  }

  @include element('line') {
    line-height: rem(20px);
  }

  @include element('icon') {
    font-size: rem(32px);
    color: $--color-greyscale-100;
    margin-right: rem(5px);
  }

  @include element('divider') {
    border-bottom: 1px solid rgba(255,255,255,0.4);
    padding: 0px 10%;
    width: 100%;
    margin: 32px 0;
  }
  @include element('bank-list') {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    height: min-content;
    column-gap: 20px;
    row-gap: 16px;
    margin: 0;
    list-style: none;
    user-select: none;
  }
  @include element('bank-list-item') {
    width: rem(32px);
    height: rem(32px);
  }

  @include element("logo") {
    width: rem(124px);
    height: auto;
    cursor: pointer;
    transition: transform .4s ease-in-out;
    margin-top: 16px;

    @include media-breakpoint-up('sm') {
      // Original before change = width: rem(160px);
      width: rem(124px);

    }

    &--smaller {
      width: rem(124px);

    }

    @include media-breakpoint-up('lg') {
      width: rem(150px);

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}
@include block('footer-partner') {
  max-width: 1024px;
  margin: 0 auto;
  @include media-breakpoint-down(md) {
    padding: 0 12px;
  }
  
  h5 {
    width: 100%;
    font-weight: 500 !important;
    font-size: 24px !important;
    line-height: 24px !important;
    text-align: center;
    color: $--color-white;
    margin-bottom: 18px;

    @include media-breakpoint-up(md) {
      font-size: 46px;
    }
  }
  
  @include element('list') {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 16px;
    row-gap: 12px;
    justify-content: flex-start;
    @include media-breakpoint-up(md) {
      justify-content: center;
    }
    > * {
      flex: 0 1 calc(50% - 12px);
      @include media-breakpoint-up(md) {
        flex: 0 0 160px;
      }
    }
  }
  @include element('item') {
    position: relative;
    width: 100%;
    border: 1px solid rgb(255, 255, 255, 0.4);
    border-radius: 8px;
    text-align: center;
    height: 60px;
    cursor: pointer;
    padding: 8px 0;
    @include media-breakpoint-down(sm) {
      padding: 10px 10px;
    }
    @include element('img') {
      position: relative;
      margin: auto;
      height: 100%;
      width: auto;
      max-width: 110px;
      * {
        object-fit: contain;
      }
    }
  }
}
@include block('footer-license') {
  * {
    color: $--color-white;
    
    font-weight: 400;
    font-size: 22px;
    line-height: 22px;
    text-align: center;
    
    
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
}