@include block('input-select') {
  min-width: rem(275px);
  text-indent: initial;

  @include element('select-wrapper') {
    display: flex;
    cursor: pointer;
    height: 44px;
  }

  @include element('input-wrapper') {
    padding-left: 16px;
  }

  @include element("placeholder") {
    color: $--color-greyscale-300;
  }

  @include element("icon") {
    color: $--color-greyscale-300;
    padding-top: 8px;
    padding-right: 16px;
    text-indent: initial;

    @include modifier('toggle-down') {
      transform: rotate(180deg);
    }

    i {
      transition: all 0.5s ease;
    }
  }

  @include element("label-wrapper") {
    line-height: 18px;
  }

  @include element("label") {
    font-weight: 300;
    padding: 3px 0px 0px 0px;
    line-height: 18px;
  }

  @include element("menu-container") {
    position: absolute;
    z-index: 99;
    top: 100%;
    left: 0;
  }

  @include element("menu") {
    font-size: 20px;
    background-color: $--color-greyscale-100;
    color: $--color-greyscale-600;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    text-align: center;
    padding: 8px 16px 8px 16px;
  }

  @include element('item') {
    cursor: pointer;
    padding: 0px;
    padding-bottom: 9px;
    padding-top: 9px;
    text-align: center;

    &:hover {
      background-color: $--color-violet-100;
      border-radius: 8px;
    }

    &:active {
      background-color: $--color-violet-200;
      border-radius: 8px;
    }
  }

  @include element('bank-logo') {
    padding-top: 1px;
  }

  @include element('bank-name') {
    display: flex;
    align-items: center;
  }
}
