@include block("leaderboard-rank") {
  position: relative;

  @include element("header") {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: rem(8px);
    padding-left: rem(8px);

    @include media-breakpoint-up(md) {
      padding-right: rem(12px);
      padding-left: rem(12px);
    }
  }

  @include element("title") {
    color: $--color-greyscale-500;
    font-size: rem(16px);
    font-weight: 400;
    line-height: 1;

    @include media-breakpoint-up(md) {
      font-size: rem(20px);
    }
  }

  @include element("body") {
    margin-top: rem(8px);

    @include media-breakpoint-up(md) {
      margin-top: rem(16px);
    }
  }

  @include element("footer") {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: rem(24px);
    padding-bottom: rem(24px);

    @include media-breakpoint-up(md) {
      padding-top: rem(40px);
      padding-bottom: rem(40px);
    }
  }

  @include element("link") {
    font-size: rem(20px);
    font-weight: 400;
    line-height: 1;
    text-decoration: underline;
    text-decoration-thickness: rem(1px);
    text-underline-offset: rem(2px);
  }
}
