@include block("header") {
  top: 0;
  width: 100%;
  box-shadow: $--box-shadow-1;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 90;
  animation: transition-delay 0.1s 0.5s forwards;
  @include modifier("affiliate"){
    background-color: $--orange-100;
  }
  @keyframes transition-delay {
    0% {
      transition: background-color 0s;
    }
    100% {
      transition: background-color 0.3s;
    }
  }
  @include media-breakpoint-down('sm'){
    > .container-fluid {
      padding: 12px;
    }
  }
  @include modifier("landing"){
    background-color: #efe6fd00;
    box-shadow: none;
  }
  @include element("container") {
    max-width: none;
    padding: 0px;
    padding-left: 16px;
    padding-right: 8px;
  }

  @include element('btn-bold'){
    font-weight: bold !important;
  }

  @include element("logo") {
    width: rem(124px);
    height: auto;
    cursor: pointer;
    transition: transform .4s ease-in-out;

    @include media-breakpoint-up('sm'){
      width: rem(124px);
    }

   &--smaller {
      width: rem(124px);
      margin-left: rem(27px);
    }

    @include media-breakpoint-up('lg'){
      width: rem(150px);
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  @include element("landing-button") {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  @include element("navbar") {
    position: relative;
    align-items: center;
    margin-left: auto;
    display: flex;
    gap: 0px;
    transition: opacity color 0.3s;

    // NOTE : just highlight menu
    .switch-app {
      color: $--color-primary-1;
    }

    @include modifier('no-margin') {
      margin: initial;
    }
    .ps-notification-badge {
      top: 0;
      right: -4px;
    }
    .loading-spinner {
      vertical-align: middle;
      width: rem(32px);
      height: rem(64px);
      display: inline-block;
      overflow: hidden;
      background: transparent;
    }

    .spinner-ring {
      width: 100%;
      height: 100%;
      position: relative;
      transform: translateZ(0) scale(1);
      backface-visibility: hidden;
      transform-origin: 0 0;
    }

    .spinner-ring div {
      position: absolute;
      width: rem(14px);
      height: rem(14px);
      border: rem(2px) solid $--color-violet-200;
      border-top-color: transparent;
      border-radius: 50%;
      animation: circle-spin 500ms linear infinite;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .spinner-ring div { box-sizing: content-box; }

    &-hamberger {
      display: flex;
      position: relative;
      width: 48px;
      height: 48px;
      margin-top: 4px;
      margin-right: -14px;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      .ps-notification-badge {
        top: 50%;
        left: 50%;
        transform: translate(3px, -150%);
      }
    }

    &--hidden-on-mobile {
      @include media-breakpoint-down("md") {
        display: none;
      }
    }

    &-boundary {
      margin: 0 rem(10px);
    }

    &-text {
      font-size: $--font-size-b1;
      text-align: center;
      white-space: nowrap;
      cursor: pointer;
      &:hover {
        color: $--color-primary-1;
      }
    }

    &-avartar {
      margin: 0 12px;
      cursor: pointer;
    }

    &-amount {
      display: flex;
      align-items: center;
      gap: 4px;
      &__total_amount {
        font-size: $--font-size-b1;
      }
    }

    &-chat {
      display: flex;
      margin: 0 4px 0 12px;
      user-select: none;
      cursor: pointer;

      svg {
        font-size: 24px;
      }
      &--focus {
        color: $--color-primary-1;
      }
      &--landing {
        margin-right: 16px;
      }
      &--white {
        color: #FFF;
      }
    }
  }
}
@include block("hamburger-icon") {
  position: relative;
  width: 24px;
  height: 24px;
  margin: 10px;
  user-select: none;
  @include element('line') {
    position: absolute;
    left: 0%;
    height: 2px;
    width: 90%;
    transform-origin: center;
    transition: 0.3s;
    background-color: $--color-greyscale-600;
  }
  @include element('line-1') {
    top: 10%;
    @include modifier('active') {
      background-color: $--color-primary-1;
      top: calc(50% - 1px);
      transform: rotate(45deg);
      width: 100%;
    }
  }
  @include element('line-2') {
    top: calc(50% - 1px);
    @include modifier('active') {
      opacity: 0;
    }
  }
  @include element('line-3') {
    top: calc(90% - 2px);
    @include modifier('active') {
      background-color: $--color-primary-1;
      top: calc(50% - 1px);
      transform: rotate(-45deg);
      width: 100%;
    }
  }
}
