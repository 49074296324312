@include block("pin") {
  width: rem(288px);
  min-height: rem(154px);
  position: relative;
  border-radius: 4px;
  padding: rem(16px);
  background-color: $--color-greyscale-100;
  margin-right: auto;
  margin-left: auto;

  @include media-breakpoint-up(sm) {
    width: rem(324px);
  }

  @include media-breakpoint-up(md) {
    width: rem(360px);
    min-height: rem(146px);
  }

  @include media-breakpoint-up(lg) {
    width: rem(344px);
    min-height: rem(146px);
  }

  @include element("header") {
    display: flex;
    margin-bottom: rem(16px);
    color: $--color-greyscale-600;
    line-height: 1;
    justify-content: space-between;

    .ps-pin__title {
      font-size: rem(24px);
      font-weight: bold;
    }

    .ps-pin__subtitle {
      font-size: rem(20px);
    }

    .ps-pin__icon-close {
      position: absolute;
      top: rem(16px);
      right: rem(16px);
      font-size: rem(14px);
      color: $--color-greyscale-300;
      font-weight: 300;
      cursor: pointer;
      pointer-events: auto;
    }
  }

  @include element("block-pin") {
    color: $--color-greyscale-600;
    line-height: 1;

    .ps-pin__icon-caution {
      width: rem(15px);
      height: rem(15px);
      padding: rem(2px);
    }

    .ps-pin__title {
      font-size: rem(24px);
      font-weight: bold;
    }

    .ps-pin__subtitle {
      font-size: rem(20px);
    }

    .ps-pin__accept {
      text-align: right;
      color: $--color-violet-400;
      cursor: pointer;
    }
  }
}

@include block("pin-v2") {
  width: rem(288px);
  min-height: rem(154px);
  position: relative;
  border-radius: 4px;
  padding: rem(24px) rem(24px) 0px rem(24px);
  background-color: $--color-greyscale-100;
  margin-right: auto;
  margin-left: auto;

  @include media-breakpoint-up(sm) {
    width: rem(324px);
  }

  @include media-breakpoint-up(md) {
    width: rem(360px);
    min-height: rem(146px);
  }

  @include media-breakpoint-up(lg) {
    width: rem(344px);
    min-height: rem(146px);
  }

  @include element("header") {
    display: flex;
    margin-bottom: rem(4px);
    text-align: center;
    color: $--color-greyscale-600;
    line-height: 1;
    justify-content: space-between;

    .ps-pin-v2__title {
      font-size: rem(42px);
      font-weight: bold;
      line-height: rem(42px);
      padding-bottom: rem(40px);
    }

    .ps-pin-v2__title-set-new-pin {
      padding-bottom: rem(10px);
    }

    .ps-pin-v2__subtitle {
      font-size: rem(20px);
      line-height: rem(20px);
    }

    .ps-pin-v2__subtitle-set-new-pin {
      padding-bottom: rem(10px);
    }
  }

  @include element("block-pin") {
    color: $--color-greyscale-600;
    line-height: 1;

    .ps-pin__icon-caution {
      width: rem(15px);
      height: rem(15px);
      padding: rem(2px);
    }

    .ps-pin__title {
      font-size: rem(24px);
      font-weight: bold;
    }

    .ps-pin__subtitle {
      font-size: rem(20px);
    }

    .ps-pin__accept {
      text-align: right;
      color: $--color-violet-400;
      cursor: pointer;
    }
  }
}

@include block("change-pin-modal") {
  input.ps-input-digit__input-item {
    width: rem(36px) !important;
    height: rem(43px) !important;
    font-size: rem(26px) !important;
  }

  @include media-breakpoint-up(sm) {
    input.ps-input-digit__input-item {
      width: rem(42px) !important;
      height: rem(50px) !important;
      font-size: rem(28px) !important;
    }

    .ps-input-digit__input {
      justify-content: flex-start;
    }
  }

  @include media-breakpoint-up(md) {
    div.ps-input-digit__alert {
      justify-content: flex-start;
    }
  }
}

@include block("pin-v2-link") {

}
