@include block("tooltip") {
  padding: 0 !important;
  border: none !important;
  outline: none !important;
  background: none !important;
  color: initial !important;
  opacity: 1 !important;

  &::before, &::after {
    display: none;
  }
}
@include block("hint-info") {
  margin: 0 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #0573F3;
}