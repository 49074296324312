// @include block("vip") {
//   max-width: 720px;
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   margin: 24px auto 0;

//   h4 {
//     width: 100%;
//     font-weight: 900;
//     font-size: 20px;
//     line-height: 20px;
//     color: $--color-greyscale-500;
//     margin-bottom: 12px;
//   }
//   @include element("divinder") {
//     padding-top: 8px;
//     margin-top: 18px;
//     border-top: 1px solid rgba(128, 128, 128, 0.1);
//   }
//   @include element("header-pack") {
//     display: grid;
//     grid-template-columns: 80% 20%;
//     > * {
//       text-align: right;
//     }
//     > *:first-child {
//       text-align: left;
//     }
//     @include element("header-button") {
//       color: #555558;
//       text-decoration: none;
//       display: flex;
//       flex-wrap: nowrap;
//       justify-content: flex-end;
//     }
//   }
//   @include element("link-button") {
//     font-size: 0.8rem;
//     cursor: pointer;
//   }
//   @include element("avatar") {
//     width: 60px;
//   }
//   @include element("name") {
//     display: block;
//     width: 100%;
//     max-width: 25ch;
//     font-size: 24px;
//     font-weight: 900;
//     line-height: 24px;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     color: #2A2A2E;
//   }
//   @include element("user-info") {
//     width: auto;
//     padding: 8px 8px;
//     @include element("tier") {
//       padding: 8px 0px;
//       min-width: 200px;
//     }
//     @include element("next-level") {
//       font-weight: 400;
//       font-size: 20px;
//       line-height: 20px;
//       color: $--color-greyscale-500;
//     }
//   }
//   @include element("benefits") {
//     width: 100%;
//     display: flex;
//     flex-wrap: wrap;

//     @include modifier("claimed") {
//       opacity: 0.5;
//     }
//     @include element("benefit") {
//       width: 100%;
//       display: flex;
//       flex-wrap: nowrap;
//       flex-direction: row;
//       align-items: flex-start;
//       @include element("badge-row") {
//         width: 60px;
//         display: flex;
//         align-items: center;
//         justify-content: flex-start;
//         padding-right: 8px;
//       }
//       @include element("text-row") {
//         width: 100%;/* Lorem Ipsum is simply dummy text */
//         font-weight: 400;
//         font-size: 20px;
//         line-height: 20px;
//         color: $--color-greyscale-400;
//         li {
//           font-size: 12px;
//         }
//         span {
//           position: relative;
//           left: -6px;
//           font-size: 20px;
//         }
//       }
//     }
//   }
//   @include element("achievement") {
//     width: 100%;
//   }
//   @include element("rewards") {
//     width: 100%;
//   }
// }
@include block("vip-rewards-group") {
  width: 100%;
  margin-bottom: 64px;
  @include element("arrow-icon") {
    transition: transform .24s;
    font-size: rem(17px) !important;
    height: rem(16px);
    top: 35% !important;
    right: 16px !important;
    color: $--color-greyscale-600;
    @include modifier("toggle-down") {
      transform: rotate(180deg) !important;
    }
    @include modifier("toggle-up") {
      transform: rotate(0deg) !important;
    }
  }
  @include element("panel-title") {
    width: 100%;
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
    >*:first-child {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row-reverse;
      background: #F0EAFF;
      .ant-collapse-header-text {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    @include element("panel-badge") {
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 100%;
      margin-right: 8px;
      @include modifier("bronze") {
        background: $--vip-bronze
      }
      @include modifier("silver") {
        background: $--vip-silver
      }
      @include modifier("gold") {
        background: $--vip-gold
      }
      @include modifier("diamond") {
        background: $--vip-diamond
      }
    }
  }
  @include element("panel-content") {
    width: 100%;
  }
  @include element("reward") {
    display: grid;
    grid-template-columns: 60px 100px auto 60px;
    width: 100%;
    margin: 0 0px 12px;

    &:first-child {
      margin-top: 36px;
      @include element("label") {
        display: block;
      }
    }
    > * {
      position: relative;
      height: 32px;
    }
    @include media-breakpoint-up("md") {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    @include element("label") {
      display: none;
      width: 100%;
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translate(-50%,-50%);
      
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
      color: $--color-primary-1;
      margin-bottom: 4px;
      white-space: nowrap;
      *:first-child {
        text-align: left;
      }
      *:last-child {
        text-align: right;
      }
    }
    @include element("level") {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    @include element("turn-over") {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: 100px;

      color: $--color-greyscale-400;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
    }
    @include element("star") {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      min-width: 100px;
    }
    @include element("claim-button") {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      height: 32px;
      width: 60px;

      > span {
        opacity: 0.3;
      }
    }
  }
  @include element("button") {
    @include modifier("disabled") {
      filter: grayscale(1);
    }
  }
}