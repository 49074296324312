@include block('shipping-form') {
  @include element('input-layout') {
    margin-bottom: rem(15px);

    @include modifier('textarea') {
      max-height: none !important;
      margin-bottom: rem(15px);
    }
  }
  
  @include element('input') {
    font-weight: 300;

    .ps-input-title {
      font-size: rem(20px);
    }

    input, textarea {
      font-weight: 300;
    }

    ::placeholder {
      font-size: rem(24px);
      font-weight: 300;
      color: $--color-greyscale-300;
    }
  }
  
  @include element('remark') {
    font-size: rem(18px);
    font-weight: 300;
    color: $--color-greyscale-400;
  }

  @include element('button-wrapper') {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
  }

  @include element('button') {
    @include modifier('cancel') {
      box-shadow: none;
    }
    @include modifier('clear') {
      font-size: rem(20px);
      font-weight: 300;
    }
  }
}