@include block("guild-search") {
  margin-top: 16px;
  display: flex;
  align-content: center;
  align-items: center;
  column-gap: 8px;
  @include element("create-button") {
    width: 140px;
    height: 5ch;
  }
}
@include block("my-guild") {
  @include element("copy-button") {
    border-radius: 4px;
    border: none;
    height: 3ch;
    padding: 2ch 0.5ch;
    display: flex;
    align-content: center;
    align-items: center;

    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    letter-spacing: 0em;
  }
  @include element("requests-button") {
    height: 5ch;
    font-size: 18px;
    font-weight: 400;
  }
  @include element("quit-button") {
    height: 5ch;
    font-size: 18px;
    font-weight: 400;
    float: right;
  }
}
@include block("guild-members") {
  margin: 6px 0px 64px;
}
@include block("guild-member") {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  column-gap: 12px;
  border-radius: 4px;
  background-color: #f4f4f4;
  margin-bottom: 4px;
  padding: 8px 16px 28px;
  @include element("member-name") {
    width: 100%;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: $--color-greyscale-600;
    margin-top: 4px;
    @include element("member-role") {
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      margin-top: 4px;
      color: $--color-greyscale-500;
    }
  }
  @include element("member-menu") {
    width: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
@include block("member-avatar") {
  position: relative;
  padding: 0;
  min-width: 40px;
  width: 40px !important;
  height: 40px !important;
  @include element("online-badge") {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #408558;
    border: 2px solid white;
    bottom: 0;
    right: -7px;
    position: absolute;
    z-index: 4;
  }
  @include element("role-badge") {
    position: absolute;
    top: -7px !important;
    left: -7px !important;
    z-index: 2;
  }
  @include element("avatar") {
    border-radius: 100%;
    overflow: hidden;
    width: 40px;
    height: 40px;
    z-index: 1;
    @include modifier("is-me"){
      border: 2px solid $--color-primary-1;
      margin: -2px;
    }
  }
}
@include block("join-request-list") {
  min-height: 300px;
  user-select: none;
  @include element("empty") {
    display: flex;
    align-content: stretch;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    justify-content: center;
    color: $--color-greyscale-300;
    font-weight: 400;
    font-size: 24px;
  }
}
@include block("join-request") {
  display: flex;
  height: 58px;
  background: $--color-violet-100;
  border-radius: 4px;
  margin-bottom: 4px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;

  @include modifier("disabled") {
    filter: grayscale(100%);
    pointer-events: none;
  }
  @include element("info") {
    width: calc(100% - 60px);
    white-space: nowrap;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 5px;
    font-weight: 400;
    font-size: 22px; 
    span {
      display: block;
      font-weight: 800;
    }
  }
  @include element("name") {
    max-width: calc(100% - 40px);
  }
  @include element("actions") {
    display: flex;
    > * {
      font-size: 20px;
      margin-left: 16px;
    }
    > *:first-child {
      margin-left: 4px;
    }
  }
}
@include block("guild-icon") {
  @include modifier("on-selector"){
    width: 66px;
    height: 66px;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
  }
  @include modifier("my-guild") {
    width: 66px;
    height: 66px;
  }
}
@include block("guild-banners") {
  @include modifier("on-selector"){
    aspect-ratio: 2 / 1;
    width: 100%;
    max-width: 260px;
    padding: 16px;
    border-radius: 4px;
    min-height: 66px;
    object-fit: cover;
    grid-gap: 0px;
    cursor: pointer;
  }
}
@include block("member-menu") {
  margin: -12px -16px;
}
@include block("member-menu-button") {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: $--color-greyscale-600;
  white-space: nowrap;
  border-width: 0 0 1px 0;
}

// TOURNAMENT
@include block("guild-tournament-banner") {
  position: relative;
  width: calc(100% + 24px);
  margin: 8px -12px;
  @include modifier("disabled"){
    pointer-events: none;
    @include element("arrow"){
      opacity: 0;
    }
  }
  @include element("banner"){
    position: relative;
    height: 100px;
    @include element("card"){
      position: absolute;
      top: 50%;
      left: 50%;
      height: 80px;
      width: 270px;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      align-items: center;
      text-shadow: 0 0 3px rgb(255, 255, 255);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      
      @include element("title"){
        position: absolute;
        top: 0px;
        justify-self: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: $--color-greyscale-600;
      }
      @include element("prize"){
        position: absolute;
        bottom: 10px;
        justify-self: center;
        font-weight: 900;
        font-size: 46px;
        line-height: 46px;
        color: $--color-greyscale-600;
      }
    }
  }
  @include element("arrow"){
    position: absolute;
    top: 40%;
    left: 1%;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 24px;
    color: white;
    text-shadow: 0 0 5px black;
    z-index: 5;
    @include media-breakpoint-up(md) {
      right: 5%;
      &:first-child {
        right: 5%;
        left: auto;
      }
    }
    &:last-child {
      right: 1%;
      left: auto;
    }
  }
  .ant-carousel {
    .slick-dots {
      margin-right: 0%;
      margin-left: 0%;
    }
  }
}