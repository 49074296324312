/**
 * Icons
 */
@font-face {
  font-family: "ps-icons";
  src: url("/fonts/ps-icons/ps-icons.eot");
  src: url("/fonts/ps-icons/ps-icons.eot?#iefix") format("embedded-opentype"),
    url("/fonts/ps-icons/ps-icons.woff") format("woff"),
    url("/fonts/ps-icons/ps-icons.ttf") format("truetype"),
    url("/fonts/ps-icons/ps-icons.svg") format("svg");

  font-weight: normal;
  font-style: normal;
  font-display: block;
}
