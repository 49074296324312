@include block('tournament-container') {
  max-width: 800px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
  @include element('title') {
    margin: 18px 6px 6px;
    width: 100%;
    font-weight: 900;
    font-size: 34px;
    line-height: 34px;
    align-items: center;
    text-align: center;
    color: $--color-greyscale-600;
  }
}
@include block('tournament-list') {
  width: 100%;
  display: grid;
  position: relative;
  justify-content: center;
  grid-template-columns: 100%;
  margin-bottom: 64px;
  @include media-breakpoint-up('sm') {
    grid-template-columns: 360px;
  }
  @include media-breakpoint-up('md') {
    grid-template-columns: 360px 360px;
  }
  @include modifier('empty') {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 272px;
    text-align: center;
    opacity: 0.3;
  }
}
@include block('tournament') {
  margin-bottom: 10px;
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@include block('tournament-card') {
  position: relative;
  background: #f2f2f2;
  border-radius: 4px;
  height: 200px;
  width: 100%;
  margin-bottom: 8px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  @include element('time') {
    position: absolute;
    margin-top: 6px;
    margin-left: 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #bfbfbf;
    display: flex;
    align-items: center;
    z-index: 2;
    *:first-child {
      margin-right: 0.5ch;  
    }
  }
  @include element('banner') {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    background: #f2f2f2;
    height: calc(100% - 52px);
    width: 100%;
    z-index: 1;
    img {
      object-fit: cover;
    }
  }
  @include element('content') {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: #f2f2f2;
    height: 52px;
    width: 100%;
    padding: 10px 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-around;
    box-sizing: border-box;
    z-index: 3;

    > * {
      width: 50%;
      display: flex;
      line-height: 1.6ch;
      align-items: center;
      &:nth-child(2n) {
        text-align: right;
        justify-content: flex-end;
      }
    }
    @include element('name') {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      color: $--color-greyscale-600;
      white-space: nowrap;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; 
        width: 100%;
      }
    }
    @include element('label') {
      font-weight: 400;
      font-size: 16px;
      color: $--color-violet-300;
    }
    @include element('detail') {
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: $--color-greyscale-400;
      overflow: hidden;
      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
      }
    }
    @include element('prize') {
      font-weight: 900;
      font-size: 34px;
      background: linear-gradient(100.77deg, #FF9100 42.4%, #FFB24D 84.17%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;      
    }
  }
}
@include block('tournament-card-winner') {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  img {
    max-height: 42px;
  }
  @include element('info') {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 50px);
    max-height: 42px;
    font-weight: 500;
    font-size: 20px;
    line-height: 16px;
    color: $--color-greyscale-500;

    *:first-child {
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: $--color-greyscale-300;
    }
  }
}
@include block('tournament-game-list') {
  width: 60%;
  height: 36px;
  display: flex !important;
  align-items: center;
  column-gap: 4px;
  user-select: none;
  padding-right: 8px;
  @include element('exceed') {
    padding-top: 4px;
    font-size: 16px;
    line-height: 16px;
    color: $--color-greyscale-400;
    font-weight: 600;
    opacity: 0.5;
  }
  @include block('tournament-game-item') {
    cursor: pointer;
    @include element('image') {
      border-radius: 100%;
      overflow: hidden;
      background-color: rgb(183, 183, 183) !important;
      border: 1px solid rgb(183, 183, 183) !important;
    }
  }
}
@include block('tournament-action') {
  width: 40%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row-reverse;
  column-gap: 8px;
  pointer-events: initial;
}

// RANKING
@include block('tournament-ranking-banner') {
  position: relative;
  padding: 0px;
  width: 100%;
  max-width: 360px;
  margin: 0px -8px;
  @include modifier("disabled"){
    pointer-events: none;
    @include element("arrow"){
      opacity: 0;
    }
  }
  @include element("slide"){
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    width: calc(100% - 16px) !important;
    margin: 0px 8px;
  }
  @include element("arrow"){
    position: absolute;
    top: 32%;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 12px;
    color: $--color-greyscale-400;
    text-shadow: 0 0 5px black;
    z-index: 5;
    transition: opacity 0.3s;
    right: calc(100% - 4px);
    justify-content: flex-end;
    @include modifier("right"){
      left: calc(100% - 4px);
      justify-content: flex-start;
      right: auto;
    }
  }
  .ant-carousel {
    .slick-dots {
      margin-right: 0%;
      margin-left: 0%;
      bottom: -8px;
      button {
        background: #9d9d9d;
      }
      .slick-active {
        button {
          background: $--color-primary-1 !important;
        }
      }
    }
  }
}

@include block('tournament-ranking') {
  position: relative;
  padding: 0px;
  display: flex;
  row-gap: 4px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0px auto 64px;
  width: 100%;
  > div {
    width: 100%;
  }
}
@include block('tournament-ranking-label') {
  width: 100%;
  max-width: 540px;
  padding: 0px 6px;
  margin: 4px auto 0px;
  display: grid;
  grid-template-columns: 20px 50px auto 64px 80px;
  column-gap: 4px;
  white-space: nowrap;
  font-size: 16px;
  color: $--color-primary-1;
  user-select: none;
  @include modifier('right') {
    text-align: right;
  }
}