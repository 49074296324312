@include block("winner-trophy") {
  position: relative;
  width: rem(88.36px);
  height: rem(129px);
  background: {
    color: transparent;
    repeat: no-repeat;
    position: center center;
    size: auto 100%;
  };

  @include media-breakpoint-up(md) {
    width: rem(143.15px);
    height: rem(209px);
  }

  @include modifier("first") {
    width: rem(100px);
    height: rem(146px);
    background-image: url("/images/leaderboard/ribbon-first-normal.png");

    @include media-breakpoint-up(md) {
      width: rem(154.79px);
      height: rem(226px);
    }
  }

  @include modifier("first-active") {
    width: rem(100px);
    height: rem(146px);
    background-image: url("/images/leaderboard/ribbon-first-active.png");

    @include media-breakpoint-up(md) {
      width: rem(154.79px);
      height: rem(226px);
    }
  }

  @include modifier("second") {
    background-image: url("/images/leaderboard/ribbon-second-normal.png");
  }

  @include modifier("second-active") {
    background-image: url("/images/leaderboard/ribbon-second-active.png");
  }

  @include modifier("third") {
    background-image: url("/images/leaderboard/ribbon-third-normal.png");
  }

  @include modifier("third-active") {
    background-image: url("/images/leaderboard/ribbon-third-active.png");
  }

  @include element("wrapper") {
    position: absolute;
    right: calc(50% - #{rem(32px)});
    bottom: rem(14.14px);

    @include media-breakpoint-up(md) {
      right: calc(50% - #{rem(49.5px)});
      bottom: rem(24px);
    }
  }

  @include element("text") {
    width: rem(64px);
    height: auto;
    color: $--color-greyscale-100;
    font-size: rem(16px);
    font-weight: 900;
    line-height: 1;
    text-align: center;

    &:last-child {
      font-weight: 400;
    }

    @include media-breakpoint-up(md) {
      width: rem(99px);
      font-size: rem(24px);
    }
  }

  &.ps-ribbon--first > .ps-ribbon__wrapper,
  &.ps-ribbon--first-active > .ps-ribbon__wrapper {
    bottom: rem(16px);

    @include media-breakpoint-up(md) {
      bottom: rem(24px);
    }
  }
}
