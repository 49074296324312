@include block("game-icon") {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 22%;
  border: 1px inset #8080801c;

  transition: 0.2s cubic-bezier(0.17, 1, 0.49, 1.82);
  &:active {
    transform: scale(0.95);
  }
  @include element("company-frame") {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;

    > div,
    span {
      width: 100% !important;
      height: 100% !important;
      border-radius: 22%;
    }
  }

  @include element("cover") {
    border-radius: 22%;

    @include modifier("top-layer") {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
}

@include block("new-game-icon-frame") {
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: 11;

  @include element("icon") {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    font-weight: 500;
    padding: 0px 6px;
    background-color: $--color-greyscale-600;
    color: $--color-greyscale-100;
    border-radius: 6px;
    border-radius: 62px 32px 32px 0px;

    // position: absolute;
    // bottom: 1px;
    // left: 50%;
    // transform: translateX(-50%);
    // border-radius: 8px 8px 0px 0px;
    // line-height: 14px;
  }
}
