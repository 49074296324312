@include block("search") {
  width: 100%;
  position: relative;
  margin: 0px;

  @include element("input") {
    width: 100%;
    height: 44px;
    display: flex;
    border: 2px solid $--color-greyscale-400;
    border-radius: 4px;
    font-weight: 400;
    box-shadow: none;

    &:focus,
    &:hover {
      outline: none;
      border-color: $--color-greyscale-400;
    }

    & > input {
      font-size: rem(24px);
      margin-left: 10px;

      &:placeholder-shown {
        color: $--color-greyscale-300;
      }
    }
  }

  @include element("icon-img") {
    opacity: 0.4;
  }

  @include element("result-container") {
    height: fit-content;
    width: 100%;
    max-height: rem(220px);
    overflow: hidden;
    overflow-y: scroll;
    z-index: 89;
    position: absolute;
    background: $--color-greyscale-100;
    box-shadow: 0px 4px 8px rgba(42, 42, 46, 0.15);
    border-radius: 4px;
    margin-top: -24px;
  }
  @include element("list") {
    height: rem(44px);
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    font-size: rem(20px);
    font-weight: 400;
    color: $--color-greyscale-600;
    border-bottom: 1px solid $--color-greyscale-200;
    cursor: pointer;
    &:hover {
      background-color: $--color-violet-100;
      color: $--color-violet-400;
    }
  }
}

@include block("search-blank") {
  display: flex;
  justify-content: center;
  height: rem(300px);
  align-items: center;
  font-size: rem(42px);
}

@include block("filter") {
  margin: rem(20px auto);
  margin-top: rem(0px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include element("header") {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 12;
    background-color: $--color-white;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    overflow: hidden;

    @include modifier("pt") {
      padding: 12px 24px;
    }
  }

  @include element("container") {
    position: relative;
  }

  @include element("btn") {
    margin-left: rem(4px);
    width: rem(44px);
    height: rem(44px);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $--color-greyscale-400;
    background-color: transparent;
    border-radius: 4px;
    color: $--color-greyscale-400;
    font-size: rem(18px);
    cursor: pointer;

    @include modifier("disabled") {
      opacity: 0.5;
      pointer-events: none;
    }
    @include modifier("fav-active") {
      color: $--yellow-200;
    }
  }

  @include element("label") {
    font-weight: 400;
    font-size: rem(20px);
    line-height: 16px;

    @include media-breakpoint-up("md") {
      font-size: rem(20px);
      line-height: 20px;
    }

    @include modifier("mb") {
      margin-bottom: -5px;
    }

    @include modifier("mb-2") {
      margin-bottom: 16px;
    }
  }

  @include element("underline") {
    margin: 0px;
  }

  @include element("clean") {
    text-decoration-line: underline;
    font-size: rem(16px);
    color: $--color-greyscale-400;
    cursor: pointer;
  }

  @include element("options-container") {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @include element("hidden") {
    display: none;
  }

  @include element("checkbox") {
    font-weight: 400;
    font-size: rem(20px);
    line-height: 16px;
    display: flex;
    align-items: center;
    margin-left: 0px;
    margin-top: rem(15px);

    @include media-breakpoint-up("md") {
      font-size: rem(20px);
      line-height: 20px;
    }

    .ant-checkbox {
      transform: scale(0.7);

      .ant-checkbox-inner {
        border-radius: 0px;
        border-color: $--color-greyscale-400;
      }
    }
  }

  @include element("filter-menu") {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  @include element("title") {
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
  }

  @include element("select") {
    padding: 6px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $--color-greyscale-400;
    border-radius: 32px;
    color: $--color-greyscale-600;
    font-size: rem(20px);
    line-height: 16px;
    margin-bottom: 8px;
    cursor: pointer;
    @include media-breakpoint-up("md") {
      font-size: rem(20px);
      line-height: 20px;
      padding: 8px 16px;
    }
  }
  @include element("result-container") {
    width: 100vw;
    z-index: 89;
    // position: absolute;
    // top: 0;
    // left: 0;
    background: $--color-greyscale-100;
    border-radius: 10px;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2);
    height: calc(100vh - 176px);
    margin-top: 0px;
    @include media-breakpoint-up("md") {
      right: 0;
    }
    @media (min-width: 488px) {
      width: calc(100vw - 32px);
      max-width: rem(420px);
      height: fit-content;
      right: -48px;
      box-shadow: 0px 4px 8px rgba(42, 42, 46, 0.15);
      right: 0;
    }

    @include modifier("with-company") {
      height: calc(100vh - 265px);

      @include media-breakpoint-up("md") {
        right: 0;
      }
      @media (min-width: 488px) {
        width: calc(100vw - 32px);
        max-width: rem(420px);
        height: fit-content;
        right: -48px;
        box-shadow: 0px 4px 8px rgba(42, 42, 46, 0.15);
        right: 0;
      }
    }
  }

  @include element("inputs") {
    max-height: calc(100vh - 295px);
    overflow: auto;
    padding: 24px;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 40px;
    margin-top: 50px;

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f3ecfd;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $--color-violet-300;
      border-radius: 8px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: pink;
    }
  }

  @include element("button-container") {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    background-color: $--color-greyscale-100;
    border-top: 1px solid $--color-greyscale-200;
  }

  @include element("button") {
    width: 80%;
    max-width: 400px;
    margin: 10px 36px;
    display: flex;
    justify-content: center;
  }
}

.ant-menu {
  padding: 0;
  box-shadow: 0px 4px 8px rgba(42, 42, 46, 0.15);
  border-radius: 4px;
}

@include block("menu") {
  width: 100vw;
  @include media-breakpoint-up("md") {
    width: rem(400px);
  }
  .ant-menu-item {
    padding: 0;
  }
  @include element("item") {
    height: rem(44px);
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    font-size: rem(20px);
    font-weight: 400;
    color: $--color-greyscale-600;
    border-bottom: 1px solid $--color-greyscale-200;
    margin: 0px;
    margin-bottom: 0px !important;
    cursor: pointer;
    &:hover {
      background-color: $--color-violet-100;
      color: $--color-violet-400;
    }
    @include modifier("active") {
      background-color: $--color-violet-100 !important;
      color: $--color-violet-400;
    }
  }
}

@include block("search-sort") {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: $--color-greyscale-400;
  cursor: pointer;
  &:hover,
  &:focus {
    color: $--color-violet-400;
  }

  @include element("icon") {
    margin-left: rem(4px);
    font-size: rem(15px) !important;
  }
}

@include block("search-banner") {
  margin: 0 -20px;

  @include media-breakpoint-up("md") {
	margin: 0px;;
  }
}
