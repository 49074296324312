@include block('faq'){
  @include element('title') {
    text-align: center;
    margin: rem(16px) 0px;
	& > h1 {
	  font-size: rem(45px);
	}
  }
  @include element('contact-admin') {
    color: #0573F3;
    white-space: nowrap;
    cursor: pointer;
  }
  @include element('modal-container') {
    padding: rem(16px) rem(16px);
    background-color: white;
    @include media-breakpoint-up(lg) {
      padding: rem(8px) rem(16px);
      width: rem(344px);
      height: rem(348px);
      margin: auto;
      > a {
        padding: 0px rem(7px) 0px rem(16px);
      }
    }
  }
  @include element('modal-title') {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(4px);
  }
  @include element('modal-detial') {
    font-size: rem(20px);
    line-height: 0px;
    padding-bottom: rem(22px);
    @include media-breakpoint-up(lg) {
      font-size: rem(20px);
      line-height: 0px;
      padding-bottom: rem(24px);
    }
  }
  @include element('modal-icon-close') {
    font-size: rem(14px);
    color: $--color-greyscale-300;
    font-weight: 300;
    cursor: pointer;
    pointer-events: auto;
  }
  @include element('modal-contactus') {
    font-size: rem(24px);
    font-weight: 600;
  }
  @include element('contactus-container')
  {
    > a {
      padding: 0px rem(7px) 0px rem(16px);
    }
  }
  @include element('modal-contactus-container')
  {
    > a {
      pointer-events: auto;
      margin-bottom: rem(4px) !important;
      @include media-breakpoint-up(lg) {
        pointer-events: auto;
        margin-bottom: rem(8px) !important;
      }
    }
  }
}

