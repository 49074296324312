@include block('transaction-history') {
  width: 100%;
  min-height: 50dvh;

  @include element('panel-item') {
    margin-bottom: rem(24px);

    > div.ant-collapse-content {
      transform: translateZ(0);

      > div.ant-collapse-content-box {
        padding: 0 !important;
      }
    }

    > div.ant-collapse-header {
      border-bottom: solid rem(1px) $--color-greyscale-200;
      font-size: rem(20px);
      line-height: rem(20px);
      padding: rem(0 0 4px 0) !important;
      user-select: none;
      touch-action: manipulation;
      z-index: 10;

      > .ant-collapse-arrow {
        right: 0;
        font-size: rem(16px);
      }
    }
  }
}
@include block('transaction-history-footer') {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 24px;
  min-height: 36px;
}
