@include block("wallet-section") {
  margin-top: rem(16px);
  margin-bottom: rem(24px);
  @include element("wrapper") {
    margin-bottom: rem(16px);
  }
  @include element("wallet-desktop"){
    position: absolute;
    display: flex;
    width: 833px;
    height: 100%;
    justify-content: space-between;
    z-index: 0;
  }
  @include element("arrow"){
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
  @include element("dots-container"){
    display: flex;
    justify-content: center;
    margin-top: rem(16px);
  }
  @include element("dots-layout"){
    display: flex;
    justify-content: space-between;
    width: rem(50px);
  }
  @include element("active"){
    background-color: $--color-primary-1;
  }

  .ant-carousel .slick-dots {
    position: absolute;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    bottom: 0;
    transform: translateY(150%);
  }
}

@include block('wallet-section-track-dots') {
  margin: 0;
  padding: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  @include media-breakpoint-up('md'){
    bottom: rem(4px);
  }
  li {
    margin: 0;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: min-content !important;
    &.slick-active {
      width: min-content;
      :first-child {
        background-color: $--color-primary-1;
      }
    }
  }
}

@include block('wallet-section-dot') {
  width: rem(8px);
  height: rem(8px);
  margin: 0;
  padding: 0;
  border-radius: $--border-radius-h;
  @include media-breakpoint-up('md'){
    width: rem(15px);
    height: rem(15px);
  }
}
