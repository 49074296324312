
@include block('skeleton') {
	background: linear-gradient(90deg, #F0F0F0 0%, #dddddd 50%, #F0F0F0 100%);
	background-size: 300%;
	animation: skeleting 12s linear infinite;
	border-radius: 4px;
	overflow: hidden;
}
@include block('skeleton-cover') {
}
@include block('skeleton-text') {
	background: linear-gradient(90deg, #F0F0F0 0%, #dddddd 50%, #F0F0F0 100%);
	background-size: 300%;
	display: block;
	width: 6rem;
	height: 1ch;
	margin: 12px 0px;
	animation: skeleting 12s linear infinite;
}
@keyframes skeleting {
	0% {
		background-position-x: 400%;
	}
	100% {
		background-position-x: -400%;
	}
}