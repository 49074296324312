@include block('promotion-detail'){
  @include element('title') {
    top : rem(10px);
    line-height: rem(41px);
    text-align: left;
    margin-bottom: 1rem;

    @include media-breakpoint-up('md') {
      font-size: rem(68px);
      line-height: rem(68px);
    }
  }
  @include element('body') {
    line-height: rem(24px);
    margin-bottom: rem(24px);
    a{
      text-decoration-line: underline;
      font-size: rem(24px);
    }
    h5{
      color: #6200EE;
    }
  }
  @include element('link') {
    display: inline-block;
    margin: rem(24px) 0 rem(36px) 0;
    .ps-icon-left {
      font-size: rem(12px);
    }
  }
  @include element('link-text') {
    padding-left: rem(8px);
    text-decoration-line: underline;
  }
  @include element('banner-container') {
    margin-top: rem(16px);
    margin-bottom: rem(16px);
    img {
      border-radius: rem(8px);
    }
  }
  @include element('banner') {
    border-radius: rem(8px);
  }
  @include element('register-button') {
    margin: 0 auto rem(36px) auto;
    font-weight: bold;
  }

}