@include block("button") {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: rem(8px 16px);
  border-radius: $--border-radius-4;
  box-shadow: $--box-shadow-5;
  cursor: pointer;
  border: none;
  outline: none;
  user-select: none;
  background-position: center;
  transition: transform 300ms;
  white-space: nowrap;

  &:disabled {
    transform: none;
    transition: none;
    opacity: 0.2;
  }

  @include element("coat") {
    display: block;
    position: absolute;

    @include modifier("tap") {
      position: absolute;
      border-radius: 50%;
      transform: scale(0);
      animation: ripple 700ms linear;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  @include element("prepend") {
    padding-left: rem(8px);

    @include modifier("sm") {
      font-size: rem(16px);
      line-height: rem(4px);
    }

    @include modifier("md") {
      line-height: rem(4px);
    }

    @include modifier("lg") {
      line-height: rem(8px);
    }
  }

  @include element("body") {
    padding: rem(0px 8px);
  }

  @include element("append") {
    padding-right: rem(8px);

    @include modifier("md") {
      line-height: rem(4px);
    }

    @include modifier("lg") {
      line-height: rem(8px);
    }
  }

  @include modifier("clicked") {
    transform: scale(0.95);
  }

  @include modifier("sm") {
    padding: rem(6px);
    font-size: rem(20px);
    font-weight: 400;
    line-height: 1;
  }

  @include modifier("md") {
    padding: rem(8px 16px);
    @extend .p-med;
  }

  @include modifier("lg") {
    padding: rem(12px 24px);
    @extend .p-black;
  }

  @include modifier("none-cta") {
    background-color: $--color-greyscale-100;
    color: $--color-greyscale-600;
  }

  @include modifier("cta-1") {
    background-color: $--color-primary-1;
    color: $--color-greyscale-100;
  }

  @include modifier("cta-2") {
    background-color: $--color-primary-2;
    color: $--color-black;
  }

  @include modifier("cta-3") {
    background-color: $--color-success;
    color: $--color-white;
  }

  @include modifier("cta-4") {
    background-color: $--color-violet-300;
    color: $--color-white;
  }

  @include modifier("outline-black") {
    background-color: $--color-transparent;
    color: $--color-greyscale-600;
    border: rem(2px) solid $--color-greyscale-600;
  }

  @include modifier("outline-white") {
    background-color: $--color-transparent;
    color: $--color-greyscale-100;
    border: rem(2px) solid $--color-greyscale-100;
  }

  @include modifier("outline-grey") {
    font-size: 20px;
    padding: 8px 2px;
    font-weight: 500;
    background-color: $--color-transparent;
    color: $--color-greyscale-600;
    border: rem(2px) solid $--color-greyscale-200;
    box-shadow: none;
  }
}

@include block("text-link") {
  @extend .p-med;
  display: block;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  border: none;
  outline: none;
  user-select: none;
  color: $--color-blue-200;
  text-decoration: none;
  background: none;

  @include element("prepend") {
    display: inline-block;
    margin-right: rem(8px);
    max-height: rem(24px);
    vertical-align: text-bottom;

    i {
      font-size: rem(14px);
    }
  }

  @include element("body") {
    display: inline-block;
    text-decoration-line: underline;
    text-decoration-thickness: rem(1px);
    text-underline-offset: rem(2px);
  }

  @include element("append") {
    display: inline-block;
    margin-left: rem(8px);
    max-height: rem(24px);
    vertical-align: text-bottom;

    i {
      font-size: rem(14px);
    }
  }

  @include modifier("shadow-padding-left") {
    padding-left: rem(16px);
  }

  @include modifier("shadow-padding-right") {
    padding-right: rem(16px);
  }

  @include modifier("extra-padding") {
    padding: rem(16px 24px);
  }

  @include modifier("disabled") {
    color: $--color-greyscale-400;

    &:hover {
      color: $--color-greyscale-400;
      cursor: default;
    }
  }
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

@include block('icon-button') {
  width: rem(44px);
  height: rem(44px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  background-color: transparent;
  color: $--color-greyscale-600;
  transition: background 0.8s;
  cursor: pointer;
  padding-top: 10px;

  &:hover {
    background: rgba(239, 230, 253, .5) radial-gradient(circle, transparent 1%, rgba(239, 230, 253, .5) 1%) center/15000%;
  }

  &:active {
    background-color: $--color-violet-200 ;
    background-size: 100%;
    transition: background 0s;
  }

  @include modifier('disabled') {
    color: $--color-greyscale-600;
    opacity: 0.2;
  }
}

@include block('favorite-button-loading') {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 22px;
  transform: scale(0.6) translateX(-10%);
}
