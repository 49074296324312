@include block('transaction-collapse') {
  // background-color: red;
// }
  width: 100%;

  > .ant-collapse-item {
    margin-top: rem(8px);
    border: none;
  }


  @include element('panel-item') {
    background-color: white;
    &:nth-child(2n){
      background-color: white;
    }
  }
  .ant-collapse-item .anticon svg {
    transform: rotate(90deg) !important;
  }
  .ant-collapse-item .anticon.no-transform svg {
    transform: none !important;
  }
  .ant-collapse-item-active .anticon svg {
    transform: rotate(-90deg) !important;
  }
  .ant-collapse-item-active .anticon.no-transform svg {
    transform: none !important;
  }

  .ant-collapse-header .ant-collapse-arrow {
    position: absolute;
    right: 5px;
    bottom: 14px;
    color: $--color-greyscale-400;
  }
}

@include block('cash-transaction-header') {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%; 

  @include element('icon'){
    display: inline-block;
    margin-right: 5px;
    padding-top: 6px;
    i {
      font-size: 14px;
    }

    @include modifier('COMPLETED') {
      color: $--color-green-200;
    }
    @include modifier('TRANSFER_BACK') {
      color: $--color-green-200;
    }
    @include modifier('PENDING') {
      color: $--color-blue-200;
    }
    @include modifier('WAITING_TRANSFER') {
      color: $--color-blue-200;
    }
    @include modifier('REJECTED'){
      color: $--color-red-200;
    }
    @include modifier('HOLD_ON') {
      color: $--color-greyscale-300;
    }

    @include modifier('ACTIVE'){
      color: $--color-green-200;
    }
    @include modifier('CONVERTED'){
      color: $--color-green-200;
    }
    @include modifier('USED'){
      color: $--color-greyscale-300;
    }
    @include modifier('EXPIRED'){
      color: $--color-greyscale-300;
    }
    @include modifier('CANCELED'){
      color: $--color-red-200;
    }
    @include modifier('REVOKED'){
      color: $--color-red-200;
    }
  }
  @include element('title'){
    display: inline-block;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
  }
  @include element('bonus-flag'){
    display: inline-block;
    margin-left: 4px;
    padding: 2px 5px;
    color: $--color-greyscale-600;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    text-align: center;
    background-color: #F6C244;
    border-radius: 4px;
  }
  @include element('amount'){
    display: inline-block;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    text-align: right;
  }
  @include element('date-time'){
    display: block;
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;    
    color: $--color-greyscale-400;
  }
}
@include block('bonus-history-content') {

  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  text-align: left;
  color: $--color-greyscale-400;
  border-top: 1px solid $--color-greyscale-200;
  margin-top: -10px;
  padding-top: 6px;
  
  @include element('status') {
    display: flex;
    justify-content: space-between;
  }
  @include element('detail') {
    display: flex;
    justify-content: space-between;
    font-weight: 400 !important;
  }
}

@include block('bonus-information-content') {
  display: flex;
  justify-content: space-between;
  font-size: rem(20px);
  line-height: rem(20px);

  strong {
    font-size: rem(22px);
    font-weight: 900;
  }

  @include element('left') {
    display: flex;
    flex-direction: column;
    @include modifier('turnover-detail') {
      margin-top: rem(4px);
      margin-bottom: rem(16px);
    }
    @include modifier('bonus-layout') {
      display: flex;
      justify-content: space-between;
      white-space: nowrap;
    }
    @include modifier('bonus-title') {
      display: flex;
      flex-direction: column;
      width: rem(63px);
      :not(:first-child) {
        margin-top: rem(4px);
      }
    }
    @include modifier('bonus-detail') {
      display: flex;
      flex-direction: column;
      text-align: end;
      :not(:first-child) {
        margin-top: rem(4px);
      }
    }
  }

  @include element('right') {
    width: rem(100px);
    height: rem(100px);
    @include modifier('title') {
      position: absolute;
      top: 29%;
    }
    @include modifier('percent') {
      position: absolute;
      line-height: rem(35px);
      top: 44%;
    }
  }
}