@import '~bootstrap/scss/mixins';

/**
 * BEM
 */
 @mixin block($block) {
  $B: $--bem-namespace + '-' + $block !global;

  .#{$B} {
    @content;
  }
}
@mixin element($element) {
  $E: $element !global;
  $selector: &;
  $currentSelector: '';
  @each $unit in $element {
    $currentSelector: #{$currentSelector +
      '.' +
      $B +
      $--bem-element-separator +
      $unit +
      ','};
  }
  @if hitAllSpecialNestRule($selector) {
    @at-root {
      #{$selector} {
        #{$currentSelector} {
          @content;
        }
      }
    }
  } @else {
    @at-root {
      #{$currentSelector} {
        @content;
      }
    }
  }
}
@mixin modifier($modifier) {
  $selector: &;
  $currentSelector: '';
  @each $unit in $modifier {
    $currentSelector: #{$currentSelector +
      & +
      $--bem-modifier-separator +
      $unit +
      ','};
  }

  @at-root {
    #{$currentSelector} {
      @content;
    }
  }
}
@mixin when($state) {
  @at-root {
    &.#{$--bem-state-prefix + $state} {
      @content;
    }
  }
}
