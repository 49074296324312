@include block("physical-category-select") {

  width: 100%;

  div.ps-input {
    border: rem(1px) solid $--color-greyscale-600;
    border-radius: rem(16px);
    font-size: rem(16px);
    font-weight: 500;

    &:focus, &:hover {
      border-color: $--color-greyscale-600;
    }
  }

  div.ps-input-select {
    min-width: auto;
    height: rem(32px);

    &__select-wrapper {
      height: inherit;
    }
  }

  div.ps-input-select__bank-name {
    font-size: rem(20px);
    line-height: rem(20px);
    justify-content: center;
    white-space: nowrap;
  }

  div.ps-input-select__menu-container {
    width: 100%;
    top: 102.7%;
    .ps-input-select__menu {
      padding: 0;
      .ps-input-select__menu-wrapper {
        margin: 0;
      }
    }

    .ps-input-select__item {
      padding: rem(15px) 4.4% rem(15px) 0;

      &--active {
        color: $--color-violet-400;
        border-radius: 0;
      }

      &:hover, &:active {
        color: $--color-violet-400;
        background-color: $--color-violet-100;
        border-radius: 0;
      }

      &:first-child:hover, &:first-child:active {
        background-color: $--color-violet-100;
        border-top-left-radius: rem(4px);
        border-top-right-radius: rem(4px);
      }

      &:last-child:hover, &:last-child:active {
        background-color: $--color-violet-100;
        border-bottom-left-radius: rem(4px);
        border-bottom-right-radius: rem(4px);
      }
    }
  }

  div.ps-input-select__icon {
    padding-top: rem(6px);
    padding-right: rem(8px);
    i.ps-icon-up {
      font-size: rem(10px);
      font-weight: 500;
      color: $--color-greyscale-600;
      transform-origin: center;
    }
  }
}