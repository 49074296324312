@include block('champion-footer-hidden'){
  opacity: 0.3;
  display: none;
}
@include block('champion-label'){
  margin-top: rem(16px);
  margin-bottom: rem(18px);
  font-style: normal;
  font-weight: 900;
  font-size: rem(34.56px);
  line-height: rem(35px);
  color: $--color-greyscale-600;
}

@include block('champion-cards'){
  user-select: none;
  @include modifier('fetched-champion'){
    @include block('champion-card'){
      &:nth-child(10n+1) { --nth-child: 0 }
      &:nth-child(10n+2) { --nth-child: 1 }
      &:nth-child(10n+3) { --nth-child: 2 }
      &:nth-child(10n+4) { --nth-child: 3 }
      &:nth-child(10n+5) { --nth-child: 4 }
      &:nth-child(10n+6) { --nth-child: 5 }
      &:nth-child(10n+7) { --nth-child: 6 }
      &:nth-child(10n+8) { --nth-child: 7 }
      &:nth-child(10n+9) { --nth-child: 8 }
      &:nth-child(10n+10) { --nth-child: 9 }
  
      animation: championAppear 0.5s calc(0s + var(--nth-child) * 0.2s) forwards cubic-bezier(0, 0.5, 0.6, 1.15);
      @keyframes championAppear {
        from {
          transform: translateX(10%);
          opacity: 0;
        }
        to {
          transform: translateX(0%);
          opacity: 1;
        }
      }
    }
  }
  @include modifier('new-champion'){
    display: flex;
    flex-direction: column-reverse;
    @include block('champion-card'){
      animation: championAppear 0.5s forwards cubic-bezier(0, 0.5, 0.6, 1.15);
      @keyframes championAppear {
        from {
          transform: translateX(10%);
          opacity: 0;
        }
        to {
          transform: translateX(0%);
          opacity: 1;
        }
      }
    }
  }
}

@include block('champion-load-more'){
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4ch;
  width: auto;
  padding: 0.5ch 2ch;
  margin: 1rem auto 0;
  text-align: center;
  color: $--color-blue-200;
  cursor: default;

  @include modifier('ended'){
    color: $--color-greyscale-600;
    opacity: 0.6;
  }
  @include modifier('clickable'){
    cursor: pointer;
  }
}

@include block('lobby-champion-slider') {
  @include block('lobby-champion') {
    display: flex;
    > * {
      width: 100%;
    }
  }
}