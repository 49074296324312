/*datepicker*/
.ant-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: $--color-greyscale-500;
  font-size: rem(24px);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  padding: rem(4px 11px 4px);
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #fff;
  min-width: rem(117px);
  height: rem(44px);
  border: rem(2px) solid $--color-greyscale-400;
  border-radius: rem(4px);
  transition: border 0.3s, box-shadow 0.3s;
}

.ant-picker-input>input {
  font-size: rem(24px);
}

.ant-picker-footer {
  display: none;
}

.ant-picker-dropdown {
  font-size: 20px;
  color: $--color-greyscale-600;
}

.ant-picker:hover,
.ant-picker-focused {
  border-right-width: rem(2px) !important;
  box-shadow: none !important;
}

.ant-picker.ant-picker-disabled {
  background: $--color-greyscale-200;
  border-color: $--color-greyscale-200  !important;
  cursor: not-allowed;
}

.ant-picker.ant-picker-disabled:active,
.ant-picker-disabled:hover,
.ant-picker-disabled {
  background: $--color-greyscale-200;
  border-color: $--color-greyscale-200  !important;
  cursor: not-allowed;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  border-radius: rem(16px);
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border-radius: rem(2px);
  content: '';
  border-radius: rem(16px);
}

.ant-picker-header-view button {
  color: inherit;
  font-weight: inherit;
}

.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: rem(32px);
  height: rem(32px);
  line-height: rem(32px);
  border-radius: rem(16px);
  transition: background 0.3s, border 0.3s;
}

.ant-picker-date-panel .ant-picker-body {
  padding: rem(16px 24px);
}

.ant-picker-dropdown {
  width: rem(300px);
}

.ant-picker-date-panel {
  width: 100%;
}

.ant-picker-year-panel {
  width: 100%;
}

.ant-picker-month-panel {
  width: 100%;
}

/*checkbox*/
.ant-checkbox-inner {
  width: rem(24px);
  height: rem(24px);
  border: rem(2px) solid $--color-greyscale-600;
  border-radius: rem(4px);
}

.ant-checkbox-checked::after {
  border-radius: rem(4px);
}

.ant-checkbox-inner::after {
  top: 43%;
  left: 17.49%;
  width: rem(8px);
  height: rem(16px);
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: rem(3px) solid #fff;
  border-radius: rem(1px);
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}

/* tooltips */
.ant-tooltip {
  max-width: initial !important;
}

.ant-tooltip-inner {
  padding: 0px !important;
  background: none !important;
  box-shadow: none !important;
}

.ant-tooltip-arrow-content {
  display: none !important;
}

/* spin */
.ant-spin-dot-item {
  background-color: $--color-primary-1 !important;
}

.ant-spin {
  color: $--color-violet-200;
}

.ant-popover {
  z-index: 80;
}

.ant-input {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;

  color: $--color-greyscale-600;
}

.ant-popover-inner-content {
  padding: 0;
}
.ant-popover-placement-bottomRight {
  padding-top: 0;
}

.ant-input-affix-wrapper {
  width: 100%;
  height: 44px;
  display: flex;
  border: 2px solid $--color-greyscale-400; // TODO : check, all input should follow input style on wallet page
  border-radius: 4px;
  overflow: hidden;
  align-content: center;
  align-items: center;

  &.ant-input {
    width: 100%;
    height: auto;
    border: none;
    border-radius: 0px;
    overflow: hidden;
  }

  .ant-input-prefix {
    color: $--color-greyscale-300;
    font-size: 4ch;
  }
}

input {
  &.ant-input {
    font-style: normal;
    width: 100%;
    height: 44px;
    display: flex;
    border: 2px solid $--color-greyscale-400;
    border-radius: 4px;
    overflow: hidden;
  }
}

textarea {
  &.ant-input {
    font-style: normal;
    width: 100%;
    height: 44px;
    display: flex;
    border: 2px solid $--color-greyscale-400;
    border-radius: 4px;
    overflow: hidden;
  }
}

/* Steps / Step */
.ant-steps {
  .ant-steps-item {
    padding: 0 !important;
  }

  .ant-steps-item-icon {
    margin: 0;
  }

  .ant-steps-item-title::after {
    left: 0;
    height: rem(3px);
  }
}

.ant-modal-content {
  border-radius: 4px;
  max-width: 90vw;
  margin: auto;

  .ant-modal-close {
    width: 24px;
    height: 24px;
    background-color: transparent !important;
  }
  .ant-modal-header {
    border: none;
    padding-bottom: 0px;

    .ant-modal-title {
      font-style: normal;
      font-weight: 900;
      font-size: 28px;
      line-height: 28px;
      color: $--color-greyscale-600;
    }
  }

  .ant-modal-body {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: $--color-greyscale-600;
    padding: 20px;

    b {
      padding-left: .7ch;
      padding-right: .7ch;
      font-weight: inherit;
      font-weight: 500;
    }
  }

  .ant-modal-footer {
    border: none;

    .ant-btn {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      color: $--color-greyscale-400;
      background-color: transparent;
      border: none;
      box-shadow: none;

    }
  }
}
.ant-notification-top {
  inset: 72px auto auto 50% !important;
} 

.ant-notification-notice {
  border-radius: 8px;

  .ant-notification-notice-message {
    padding-top: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px !important;
    line-height: 20px;
    color: $--color-greyscale-600;
  }

  .ant-notification-notice-description {
    display: none;
  }

  &.ant-notification-notice-success {
    border: 2px solid $--color-green-200;
    background: $--color-green-100;
  }

  &.ant-notification-notice-warning {
    border: 2px solid $--orange-200;
    background: $--orange-100;
  }

  &.ant-notification-notice-error {
    border: 2px solid $--color-red-200;
    background: $--color-red-100;
  }
  
  .ant-notification-notice-close {
    line-height: 1;
  }
}

.ant-space-item {
  font-size: 24px;
}

.ant-dropdown-menu {
  border-radius: 4px;
}

.ant-dropdown-menu-title-content {
  font-size: 20px;
  min-width: 7ch;
  text-align: right;
}

.ant-scroll-number {
  font-family: "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial";
  z-index: 20;
}

.ant-badge-count {
  box-shadow: 0 0 0 2px #FCFCFC;
  background: #c8373e !important;
  z-index: 11 !important;
}
.ant-badge-status-dot {
  width: 6px !important;
  height: 6px !important;
}

.ant-carousel .slick-list .slick-slide {
  pointer-events: initial !important;
}
.slick-dots li.slick-active {
  width: auto;
}