@include block('modal') {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: rgba($--color-greyscale-600, 0.5);
  z-index: 1200;

  @include modifier('open') {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @include element('wrapper') {
    min-width: rem(100px);
    min-height: rem(100px);
  }
}

@include block('pigspin-modal') {
  padding-right: 24px;
  @include element('text'){
    max-width: 340px;
  }
  @include element('action'){
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 24px;
    column-gap: 18px;
    @include element('button'){
      width: auto;
      font-weight: 500 !important;
      font-size: 24px !important;
      line-height: 24px !important;
      color: $--color-primary-1 !important;
      padding: 0px;
      @include modifier('close'){
        color: $--color-greyscale-300 !important;
      }
    }
  }
}
@include block('pigspin-modal-text') {
  color: $--color-greyscale-600;
  @include element('warning') {
    margin-top: 1ch;
    font-size: 0.8em;
    line-height: 2ch;
    color: #CC0000;
  }
  @include element('highlight') {
    color: $--color-primary-1;
  }
}