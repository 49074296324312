@include block("deposit-layout") {
  width: 100%;
  margin: auto;

  @include media-breakpoint-up(md) {
    width: rem(360px);
  }
  @include media-breakpoint-up(xl) {
    width: rem(528px);
  }

  @include block("information-box"){
    width: 100%;
  }
}

@include block('deposit-label'){
  width: 100%;
  font-size: rem(20px);
  font-weight: 400;
  color: $--color-greyscale-400;
  margin: 10px auto 8px;
}