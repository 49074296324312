@include block("contactus") {
  b {
    color: #6200ee;
  }
  a {
    font-size: 1rem;
  }
  @include element("title") {
    margin-top: 24px;
    text-align: center;
    * { font-size: 34px }
  }
  @include element("subtitle") {
    text-align: center;
    margin-bottom: 24px;
    white-space: pre-wrap;
    line-height: 24px;

    @include media-breakpoint-up("lg") {
      white-space: normal;
    }
  }

  @include element("container") {
    display: flex;
    flex-direction: column;
    margin-bottom: rem(24px);
  }
}
