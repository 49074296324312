.CircularProgressbar-path {
  stroke: $--color-violet-400;
  transition: 'stroke-dashoffset 0.5s ease 0s',
}
.CircularProgressbar-trail {
  stroke: $--color-violet-200;
}
.CircularProgressbar-text {
	font-weight: bold;
  fill: $--color-violet-600;
  font-size: 35px;
  dominant-baseline: middle;
  text-anchor: middle;
}

@include block('stepper-circular'){
  @include make-row();
  @include make-col-ready();

  padding: 0px 30px 0px 30px;
  justify-content: center;

  @include element('progress'){
    width: 83px;
  }
  @include element('step-wrapper'){
    text-align: right;

    @include media-breakpoint-up(xs) {
      @include make-col(8);
    }
  }
  @include element('step-title'){
    font-style: normal;
    font-weight: 900;
    font-size: $--font-size-base;
    color: $--color-greyscale-600;

    @include media-breakpoint-up(xs) {
      @include make-col(12);
    }
  }
  @include element('step-subtitle'){
    font-weight: normal;
    font-size: 20px;
    line-height: 21px;
    padding-left: 0px;
    white-space: pre-wrap;

    @include media-breakpoint-up(xs) {
      @include make-col(11);
      @include make-col-offset(1);
    }
  }
}
