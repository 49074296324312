@include block("pig-shop") {
  @include element("header") {
    text-align: center;
    padding-bottom: 0.7rem;
  }

  @include element("title") {
    text-transform: uppercase;
    font-size: rem(52px);
    font-weight: 700;
    line-height: rem(40px);
  }

  @include element("description") {
    font-size: rem(29px);
  }

  @include element("content-wrapper") {
    align-items: flex-end;
  }

  @include element("info") {
    @include media-breakpoint-up(md) {
      width: 50%;
      padding-bottom: 2%;
    }
  }

  @include element("quest-button") {
    width: rem(192px);
  }

  @include element("empty-list") {
    text-align: center;

    > div:first-child {
      margin-top: rem(30px) !important;
      margin-bottom: rem(30px) !important;
    }
  }
  @include element("empty-text") {
    width: rem(180px);
    font-size: rem(20px);
    line-height: rem(20px);
    text-align: center;
    margin: auto;
    opacity: 0.8;
    text-align: center;

    span {
      color: $--color-blue-200;
      text-decoration: underline;
    }
  }

  @include element("faq-button") {
    width: 100%;
    font-size: rem(24px);
    color: $--color-violet-300;
    border: rem(2px) solid $--color-violet-100;
    box-shadow: none;
  }

  @include element("select-wrapper") {
    margin-left: auto;
    width: rem(200px);
  }

  @include element('maintenance-title') { 
    font-size: rem(28px);
    font-weight: 700;
  }

  @include element('maintenance-sub-title') { 
    line-height: rem(24px);
  }
}

@include block("main-quest-section") {
  @include element("main-image-pig-shop") {
    position: absolute;
    bottom: 0.15rem;
    left: 0.125rem;
    width: rem(353px);

    @include media-breakpoint-up(md) {
      bottom: rem(7px);
      left: 0;
      width: 100%;
    }
  }
}

@include block("redemption-card") {
  background-color: $--color-violet-100;
  box-shadow: 0 rem(4px) rem(8px) rgba(42, 42, 46, 0.15);
  border-radius: rem(8px);
  display: flex;
  margin-bottom: rem(14px);

  @include element("gameicon"){
    width: auto;
    min-width: rem(44px);
    display: flex;
    align-items: center;
  }
  @include element("content-wrapper") {
    width: 70%;
    padding: 12px 16px;
    display: grid;
    grid-template-columns: 44px 1fr;
  }
  @include element("content") {
    padding-left: rem(10px);
    line-height: rem(22px);
    height: rem(44px);
  }
  @include element("title") {
    font-size: rem(20px);
    white-space: nowrap;
  }
  @include element("sub-title") {
    font-size: rem(14px);
    line-height: rem(14px);
    width: 80%;
    white-space: normal;
  }
  @include element("button-wrapper") {
    margin-left: auto;
  }
  @include element("button") {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 rem(8px) rem(8px) 0;
    height: 100%;
    font-size: rem(20px);
    font-weight: normal;
    line-height: rem(16px);
    width: rem(103px);
    padding: rem(2px);   
    line-height: 0.7em;
    padding-top: 0.2rem;

    span {
      padding: 0px;
      white-space: normal;
      font-size: 0.82rem;
    }
  }
  @include element("button-icon") {
    font-size: rem(25px);
    margin-top: rem(-8px);
  }
  

  @include element("history-wrapper") {
    width: 30%;
    padding: rem(12px) rem(15px);
    display: flex;
    margin-left: auto;
    align-content: space-between;
    flex-wrap: wrap;
  }
  @include element("code") {
    font-size: rem(16px);
    line-height: rem(16px);
    white-space: nowrap;
  }
  @include element("value") {
    width: 100%;
    font-size: rem(20px);
    line-height: rem(20px);
    text-align: right;
    white-space: nowrap;
  }
  @include element("subvalue") {
    width: 100%;
    font-size: rem(12px);
    line-height: rem(12px);
    text-align: right;
    white-space: nowrap;
  }
  @include element("date") {
    width: 100%;
    font-size: rem(16px);
    line-height: rem(16px);
    text-align: right;
    white-space: nowrap;
  }
}
@include block("physical-redemption") {
  margin-top: rem(-18px);;


  @include element("wrapper"){
    width: calc(100% + 8px);
    display: flex;
    flex-wrap: wrap;
    margin: rem(16px) rem(-4px) rem(0px);
    justify-content: flex-start;
  }
  @include element("blanker"){
    display: block;
  
    &::before {
      content: '1';
      display: block;
      background-color: pink;
      opacity: 0;
    }
  }
  @include element("line"){
    &:first-of-type {
      display: none;
    }
  }
}
@include block("physical-redemption-card") {
  width: 50%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0px 0 rem(16px);
  padding: 0 1.5%;
  border-radius: rem(8px);
  overflow: hidden;

  > div {
    width: 100%;
  }
    
  @include modifier("soldout"){
    opacity: 0.6 !important;
  }
  @include element("content-wrapper") {
    width: 100%;
    height: rem(145px);
    max-height: rem(145px);
    overflow: hidden;
    background-color: #f3ecfd4f;
  }
  @include element("image"){
    display: flex;
    justify-content: center;
    position: relative;
    min-width: rem(100px);
    min-height: rem(100px);
    border-radius: rem(8px) rem(8px) 0 0 ;
    overflow: hidden;
  }
  @include element("limited"){
    background-color: $--color-red-200;
    position: absolute;
    top: 0px;
    left: 0px;
    width: rem(100px);
    height: rem(40px);
    font-size: rem(16px);
    line-height: rem(16px);
    color: #FCFCFC;
    text-align: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background: #C8373E;
    transform: translate(-36px, 36px) matrix(0.67, -0.74, 0.74, 0.67, 0, 0);
    transform-origin: top left;
  }
  @include element("info") {
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @include element("title") {
    display: inline-block;
    width: 90%;
    margin: 0px auto;
    font-size: rem(19px);
    line-height: 2ch;
    text-align: center;
  }
  @include element("button-wrapper") {
    width: 100%;
  }
  @include element("button") {
    width: 100%;
    border-radius: 0 0 rem(8px) rem(8px);
    font-size: rem(24px);
    font-weight: normal;
    line-height: rem(32px);
    padding: rem(2px);
    vertical-align: middle;

    &:disabled {
      opacity: 0.7;
    }
    
    span {
      vertical-align: middle;
      transform: translateY(-1px);
    }
  }
  @include element("sold-out-label") {
    position: absolute;
    top: 24%;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    padding: rem(3px) 1.6ch;
    background-color: $--color-greyscale-600;
    color: $--color-greyscale-100;
    font-size: rem(18px);
    line-height: 3ch;
    white-space: nowrap;
    border-radius: rem(56px);
  }
}
@include block("physical-reward-detail") {
  position: relative;
  width: 100%;
  padding-top: rem(25px);
  
  img {
    margin: auto;
  }
  @include element("header") {
    position: relative;
  }

  @include element("back") {
    position: absolute;
    top: rem(5px);
    font-size: rem(20px);
    padding-left: rem(12px);
  }
  
  @include element("banner-container") {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  @include element("title") {
    width: 100%;
    max-width: 75%;
    margin: auto;
    font-size: rem(36px);
    line-height: 2ch;
    font-weight: 400;
    text-align: center;
    color: $--color-greyscale-600;
    user-select: none;
    pointer-events: none;
  }
  @include element("subtitle") {
    width: 100%;
    font-size: rem(14px);
    line-height: 4.5ch;
    font-weight: 300;
    text-align: center;
    color: $--color-greyscale-600;
  }
  @include element("detail"){
    font-size: rem(24px);
    font-weight: 200;
    line-height: rem(24px);
    color: $--color-greyscale-300;

    > h5 {
      margin-top: rem(16px);
      color: $--color-greyscale-500;

    }
    > hr {
      margin-top: rem(12px);
      margin-bottom: rem(12px);
    }
  }
  @include element("ticket-explain"){
    width: 77%;
    font-size: 20px;
    display: block;
    vertical-align: middle;
    text-align: center;
    margin: 0 auto rem(30px);
    line-height: 2.5ch;

    @include element("text-link"){
      font-size: inherit;
      display: inherit;
      display: inline-block;
      padding: 0px 4px;
    }
  }
  @include element("redeem-button"){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: rem(12px) rem(44px);
    margin: rem(40px) auto;

    border-radius: 24px;

    font-size: rem(25px);
    font-weight: 400;
    line-height: rem(24px);
    color: #FCFCFC;
    opacity: 0.2;
    pointer-events:none;

    * {
      vertical-align: middle;
    }
    span {
      margin-top: rem(-2px);
      margin-right: rem(4px);
    }
    @include modifier("active"){
      background: linear-gradient(274.19deg, #6200EE 0%, #F9C95C 100%);
      opacity: 1;
      pointer-events:initial;
    }
  }

  @include block("physical-reward-detail-slide"){
    width: 100%;
    position: relative;

    @include element("frame"){
      width: 100%;
      height: 290px;
      position: relative;
    }
    @include element("image"){
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .slick-slide {
      font-size: 0px;
    }
    .slick-dots {
      bottom: 2%;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      max-width: 600px;
      margin: auto;
      text-align: right;
      font-size: 0px;
    }
    @include element('pagination'){
      display: inline-block;
      
      font-size: rem(16px);
      line-height: rem(16px);
      color: $--color-greyscale-200;
      font-weight: 300;
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 30px;
      padding: 0.3ch 2.2ch;

      span {
        color: $--color-greyscale-100;
      }
    }
  }
  @include block("redemption-summary") {

    @include element("destination-card"){
      position: relative;
      margin-top: rem(4px);
      padding: rem(16px);
      background: $--color-violet-100;
      box-shadow: 0px 4px 8px rgba(42, 42, 46, 0.15);
      border-radius: rem(4px);
      color: $--color-greyscale-600;

      @include element("destination-name"){
        font-weight: 700;
        font-size: rem(24px);
        line-height: rem(24px);
      }
      @include element("destination-phone"){
        font-size: rem(24px);
        line-height: rem(24px);
      }
      @include element("destination-address"){
        display: flex;
        font-size: rem(20px);
        line-height: rem(20px);
        margin-top: rem(10px);
        font-weight: 400;
        align-items: start;

        i {
          padding: rem(2px) rem(5px);
        }
      }
      @include element("add-address"){
        display: flex;
        align-items: center;
        grid-gap: rem(8px);
        font-size: rem(20px);
        font-weight: 400;
        line-height: rem(20px);
      }
      @include element("detination-icon"){
        display: inline-block;
        margin-right: rem(8px);
        font-size: rem(20px);
        line-height: rem(20px);
      }
      @include element("address-button"){
        position: absolute;
        top: rem(14px);
        right: rem(19px);
        padding: 0px;
        width: rem(25px);
        height: rem(25px);
        span {
          padding: 0px;
          line-height: rem(16px);
          font-size: rem(40px);
          transform: translateY(-1px);
        }
        i {
          font-size: rem(15px);
        }
      }
    }

    @include element("item-ribbon"){
      margin: rem(20px) rem(-16px) rem(0px);
      padding: rem(20px) rem(16px) rem(16px);
      background: $--color-violet-100;
      border-radius: 4px;
      color: $--color-greyscale-600;
      
      @include element("item-container"){
        display: flex;
        flex-wrap: nowrap;
        align-content: space-between;
        margin-top: rem(16px);
        @include element("item-information"){
          display: flex;
          flex-wrap: wrap;
          align-content: space-between;
          margin-left: rem(12px);
          
          @include element("item-name"){
            width: 100%;
            font-weight: 700;
            font-size: rem(24px);
            line-height: rem(24px);
            color: $--color-violet-400;
          }
          @include element("item-warning"){
            width: 100%;
            font-size: rem(16px);
            line-height: rem(16px);
            padding-bottom: rem(6px);
            color: $--color-greyscale-600;
          }
        }
        
      }
      @include element("item-image"){
        width: 100px;
      }
    }

    @include element("tickets-ribbon"){
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin: rem(12px) rem(-16px);
      padding: rem(10px) rem(16px);
      background: $--color-violet-100;
      border-radius: 4px;
      color: $--color-greyscale-600;

      @include element("item-tickets"){
        display: flex;
        align-items: center;
        vertical-align: middle;
        line-height: 29px;
        gap: rem(4px);
        @include modifier("text"){
          padding-top: rem(2px);
        }
      }
    }
    @include element("redemption-button"){
      margin: 40px auto 22px;
    }
    @include element("tips"){
      width: 80%;
      margin: auto;
      margin-bottom: 48px;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      color: $--color-greyscale-600;
    }
    
    @include element("bold-text"){
      font-weight: 700;
      font-size: 28.8px;
      line-height: 29px;
      color: $--color-greyscale-600;
    }
  }
}





@include block("horizon-line") { 
  width: 100%;
  color: $--color-violet-200;
}
  
@include block('collapse-history') {
  .ant-collapse-content-box {
    padding: rem(10px) rem(0px) !important;
    @include media-breakpoint-up(lg) {
      padding: 8px 16px;}
  }
  .ant-collapse-item > .ant-collapse-header {
    border: 0px solid $--color-greyscale-200;
    border-width: 0px 0px 2px 0px;
    line-height: rem(20px);
    padding: rem(4px) rem(12px) rem(6px) rem(0px);
    border-radius: rem(4px) !important;
    color: $--color-greyscale-400;
  }
  @include element('arrow-icon') {
    transition: transform .24s;
    font-size: rem(17px) !important;
    height: rem(16px);
    top: 35% !important;
    right: 0px !important;
    color: $--color-greyscale-600;
    @include modifier('toggle-down') {
      transform: rotate(180deg) !important;
    }
    @include modifier('toggle-up') {
      transform: rotate(0deg) !important;
    }
  }
  @include element('panel-title') {
    font-size: rem(20px);
    margin-bottom: 10px;
    border-bottom: 0;
  }
  @include element('panel-content') { 
    white-space: pre-wrap;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    font-size: 20px;
    border-radius: 0px 0px 4px 4px;
    color: $--color-greyscale-600;
    cursor: pointer;
    > ul {
      padding-left: rem(30px);
      margin-bottom: 0
   }
    
  }
  @include element('panel-content-li') { 
    > i {
      font-size: 12px;
    }
  }
}

.ps-pig-shop__modal {
  .ps-alert .ps-alert__footer button.ps-button {
    box-shadow: none;
    font-size: rem(24px);
  }

  .ps-button--none-cta {
    color: $--color-greyscale-400;
  }

  .ps-button--cta-1 {
    color: $--color-violet-400;
    background-color: $--color-greyscale-100;
  }
}





