@function random_range($min, $max) {
  $rand: random();
  $random_range: $min + floor($rand * (($max - $min) + 1));
  @return $random_range;
}

@mixin snow($num, $max-size) {
  $total: $num;
  position: sticky;
  width: $max-size;
  height: $max-size;
  background: #fffafa;
  border-radius: 50%;

  @for $i from 1 through $total {
    $random-x: random(1000000) * 0.0001vw;
    $random-range: random_range(-100000, 100000) * 0.0001vw;
    $random-x-end: $random-x + $random-range;
    $random-x-end-yoyo: $random-x + ($random-range / 2);
    $random-yoyo-time: (random_range(15000, 65000) / 100000);
    $random-yoyo-y: $random-yoyo-time * 100vh;
    $random-scale: random_range(300, 10000) * 0.0001;
    $fall-duration: random_range(30, 56) * 1s;
    $fall-delay: random(30) * -1s;

    &:nth-child(#{$i}) {
      opacity: random_range(1000, 10000) * 0.0001;
      transform: translate($random-x, -10px) scale($random-scale);
      animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
    }

    @keyframes fall-#{$i} {
      #{percentage($random-yoyo-time)} {
        transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
      }

      to {
        transform: translate($random-x-end-yoyo, 100vh) scale($random-scale);
      }
    }
  }
}

@include block('snowfall-layout') {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 10;
  pointer-events: none;

  .snow-80 {
    @include snow(80, 8px);
  }
  .snow-150 {
    @include snow(150, 10px);
  }
  .snow-200 {
    @include snow(200, 12px);
  }
}

@mixin on_top_snow() {
  position: relative;
  z-index: 11;
}

body {
  @include block('home-hero') {
    z-index: 11;
  }
  @include block('footer') {
    @include element('bank') {
      @include on_top_snow();
    }
  }
  @include block('profile') {
    @include on_top_snow();
  }
  @include block('promotion-detail') {
    @include element('body') {
      @include on_top_snow();
    }
  }
  @include block('marquee-section') {
    @include on_top_snow();
  }
  @include block('faq') {
    @include on_top_snow();
  }
  @include block('contactus') {
    @include on_top_snow();
  }
  @include block('game-section') {
    @include modifier('popular') {
      @include on_top_snow();
    }
  }
  @include block('wallet-menu') {
    @include on_top_snow();
  }
  @include block('button') {
    @include on_top_snow();
  }
  @include block('input') {
    @include on_top_snow();
  }
  @include block('calendar') {
    @include on_top_snow();
  }
  @include block('input-select') {
    z-index: 12;
  }
  @include block('stepper-controller') {
    @include element('back-icon') {
      z-index: 12;
    }
  }
  @include block('input-digit') {
    @include element('input') {
      @include on_top_snow();
    }
  }
  @include block('leaderboard-section') {
    .container {
      @include on_top_snow();
    }
  }
  .slick-dots {
    z-index: 11;
  }
  p,
  span,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include on_top_snow();
  }
}
