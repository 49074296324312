.pn-msg-input__send {
  --msg-input__send--active__color: rgb(89, 0, 255);
}
.pn-msg-list__emoji-picker,
.pn-msg-input__emoji-picker {
  z-index: 30 !important;
}
.pn-msg__actions {
  --msg__actions--hover__color: rgb(89, 0, 255);
}
.pn-msg-list {
  --msg-list__background: #FCFCFC;
}
.pn-msg__main {
  --msg__main__maxWidth: 80%;
  --msg__main__margin: 4px 8px;
  border-radius: 4px;
  background-color: #ede7fb;
}
.pn-msg__content {
  // --msg__content__margin: 4px 8px;
}
.pn-msg__author {
  max-width: 16ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 24px;
  --msg__author__color: #7F7F82;
  --msg__author__fontSize: 20px;
  --msg__author__fontWeight: 400;
}
.pn-msg__bubble {
  line-height: 20px;
  --msg__bubble__color: #2A2A2E;
  --msg__bubble__fontWeight: 400;
  --msg__bubble__fontSize: 20px;
  user-select: none;
}
.pn-msg__time {
  --msg__time__color: #AAAAAB;
  --msg__time__fontWeight: 400;
  --msg__time__fontSize: 16px;
  --msg__time__padding: 0 0 0 10px;
}
.pn-msg-input__textarea {
  --msg-input__textarea__fontWeight: 400;
  --msg-input__textarea__fontSize: 24px;
  --msg-input__textarea__lineHeight: 24px;
  --msg-input__textarea__height: 38px;
}
.pn-msg__file {
  max-width: 200px;
  max-height: 200px;
  border-radius: 4px;
}
.pn-msg__image {
  max-width: 200px;
  max-height: 200px;
  --msg__image__margin: 0;
}
.pn-msg__title {
  --msg__title__margin: 0;
  user-select: none;
}
.pn-msg--own {
  --msg__avatar__margin: 2px 0 0 18px;
  --msg__content__alignItems: flex-end;
  --msg__flexDirection: row-reverse;
  --msg__title__flexDirection: row-reverse;
  .pn-msg__content {
    --msg__content__margin: 4px 8px;
  }
  .pn-msg__time {
    --msg__time__padding: 0 10px 0 0;
  }
  .pn-msg__actions {
    left: 10px;
  }
  .pn-msg__extras {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-end;
  }
  .pn-msg__main {
    align-items: flex-end;
    justify-content: flex-end;
    border-radius: 4px;
    background-color: #D8EEE0;
  }
  .pn-msg__reactions {
    align-items: flex-end;
    justify-content: flex-end;
  }
}
.pn-msg__extras {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-direction: column;
}
.pn-tooltip {
  &::before, &::after {
    display: none !important;
  }
}
.pn-msg__reactions {
  // position: absolute;
  // top: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 4px;
  max-width: 200px;
  .pn-msg__reaction {
    border: none;
    background: none;
    margin-top: 0px;
    margin-right: 0px;
  }
}