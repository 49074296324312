@include block('menu-divider') {
  margin-top: rem(24px);
  margin-bottom: rem(16px);
  border-top: 1px solid $--color-greyscale-200;

  @include media-breakpoint-up(lg) {
    margin-top: rem(32px);
    margin-bottom: rem(24px);
  }
}

@include block('menu-footer-link') {
  height: rem(52px);
  display: flex;
  justify-content: center;
  align-items: center;
  .ps-text-link__append {
    display: flex;
  }
}

@include block('wallet-menu') {
  padding-bottom: 24px;
  .ant-tabs-nav-wrap {
    margin-top: rem(24px);
    @include media-breakpoint-up(md) {
      justify-content: center;
    }
  }
}
