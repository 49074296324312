@include block('marquee-carousel') {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100%;

    @include element('item') {
        width: inherit;
        text-align: center;
        margin: 4px;
    }
}
