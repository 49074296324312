@include block("variant-controller") {
  @include element("loading") {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 20vh auto;
  }
}

@include block("landing-v2") {
  width: 100%;
  max-width: 100vw;
  @include media-breakpoint-up(md) {
  }
}
@include block("landing-section") {
  width: 100%;
  background-color: $--color-greyscale-100;
  user-select: none;
  position: relative;
  margin: auto;
  padding: 12px 0;
  z-index: 0;
  @include media-breakpoint-up(md) {
    padding: 12px 0 24px;
  }
  .ps-game-card {
    border-bottom: none;
  }
  @include modifier("animated-banner") {
    background-color: #170630;
    position: sticky;
    overflow: hidden;
    max-width: 100vw;
    margin-top: -64px;
    height: 80vh;
    z-index: -1;
    @include media-breakpoint-up(md) {
      height: 50vh;
    }
  }
  @include modifier("billboard") {
    margin-top: 0px;
    .ps-game-card {
      margin-top: 4px;
    }
    button {
      height: 3ch !important;
    }
  }
  @include modifier("companies") {
    margin-top: -16px;
  }
  @include modifier("services") {
    margin-top: -16px;
    @include media-breakpoint-up(md) {
      margin-top: 16px;
    }
  }
  @include element("title") {
    font-weight: 900;
    font-size: 34px;
    line-height: 34px;
    display: flex;
    align-items: center;
    color: $--color-greyscale-600;
  }
  @include element("menu-buttons") {
    position: absolute;
    bottom: calc(100% - 2px);
    width: 100%;
    padding: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    background-image: url("/images/landing/mask_o.png");
    background-position: bottom;
    background-size: 140% 100%;
    background-repeat: no-repeat;
    @include media-breakpoint-up(md) {
      background-size: 100% 100%;
    }
    @include element("menu-button") {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      align-content: center;
      pointer-events: all;
      padding: 2px 8px;
      color: $--color-greyscale-100;
      font-weight: 400;
      text-align: center;
      background-color: $--color-primary-1;
      border-radius: 4px;
      white-space: nowrap;
      cursor: pointer;
      font-size: 16px;
      line-height: 30px;
      min-width: auto;
      margin: 48px 6px 12px;
      @include media-breakpoint-up(sm) {
        min-width: 104px;
        margin: 48px 8px 12px;
      }
      @include media-breakpoint-up(md) {
        margin: 48px 12px 12px;
        min-width: 124px;
        font-size: 18px;
        line-height: 36px;
      }

      img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        filter: brightness(10);
      }
    }
  }
}
@include block("gamegroups") {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: space-around;

  @include element("button") {
    width: calc(50% - 6px);
    cursor: pointer;
    @include media-breakpoint-up(md) {
      width: calc(25% - 6px);
    }
    margin-bottom: 8px;
    border-radius: 4px;
    aspect-ratio: 166/68;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    @include modifier("evoplay") {
      background-image: url("/images/landing/group-evoplay.png");
    }
    @include modifier("joker") {
      background-image: url("/images/landing/group-joker.png");
    }
    @include modifier("pgsoft") {
      background-image: url("/images/landing/group-pg.png");
    }
    @include modifier("playgo") {
      background-image: url("/images/landing/group-playgo.png");
    }
  }
}
@include block("abstract") {
  padding: 16px;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  max-width: 670px;
  margin: auto;
  color: $--color-greyscale-600;
  @include element("title") {
    color: $--color-primary-1;
    font-size: rem(34px);
    line-height: 2.2ch;
    margin-bottom: 1ch;
  }
}
