@include block('wallet-overlay'){
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:$--color-greyscale-600;
  opacity: 0.65;
  z-index: 9999;
    clip-path: polygon(
    0% 0%,
    0% 100%,
    5% 100%, /* Bottom left sideline of clipped rectangle */
    5% 52%, /* Top left corner of clipped rectangle */
    95% 52%, /* Top right corner of clipped rectangle */
    95% 80%, /* Bottom right corner of clipped rectangle */
    5% 80%,  /* Bottom left corner of clipped rectangle */
    5% 100%, /* Bottom left baseline of clipped rectangle */
    100% 100%,
    100% 0%);
    @include media-breakpoint-up('md'){
      clip-path: polygon(
        0% 0%,
        0% 100%,
        25% 100%, /* Bottom left sideline of clipped rectangle */
        25% 52%, /* Top left corner of clipped rectangle */
        75% 52%, /* Top right corner of clipped rectangle */
        75% 75%, /* Bottom right corner of clipped rectangle */
        25% 75%,  /* Bottom left corner of clipped rectangle */
        25% 100%, /* Bottom left baseline of clipped rectangle */
        100% 100%,
        100% 0%);
    }
    @include media-breakpoint-up('lg'){
      clip-path: polygon(
        0% 0%,
        0% 100%,
        30% 100%, /* Bottom left sideline of clipped rectangle */
        30% 45%, /* Top left corner of clipped rectangle */
        70% 45%, /* Top right corner of clipped rectangle */
        70% 65%, /* Bottom right corner of clipped rectangle */
        30% 65%,  /* Bottom left corner of clipped rectangle */
        30% 100%, /* Bottom left baseline of clipped rectangle */
        100% 100%,
        100% 0%);
    }
    @include media-breakpoint-up('xl'){
      clip-path: polygon(
        0% 0%,
        0% 100%,
        30% 100%, /* Bottom left sideline of clipped rectangle */
        30% 45%, /* Top left corner of clipped rectangle */
        70% 45%, /* Top right corner of clipped rectangle */
        70% 65%, /* Bottom right corner of clipped rectangle */
        30% 65%,  /* Bottom left corner of clipped rectangle */
        30% 100%, /* Bottom left baseline of clipped rectangle */
        100% 100%,
        100% 0%);
    }
}
