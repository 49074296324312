@include block('promotion') {
  @include element('container') {
    position: relative;
  }
  @include element('title') {
    text-align: center;
    margin: rem(27px) 0 rem(16px) 0;
    line-height: rem(35px);
  }
  @include element('image-container') {
    margin-bottom: rem(16px);
    cursor: pointer;
    img {
      border-radius: rem(8px);
    }
    position: relative;
    z-index: 1;
  }
  @include element('register-button') {
    font-weight: bold;
    margin: rem(24px) auto;
  }
  @include element("divider") {
    margin-top: 0;
    margin-bottom: 0;
    background-color: $--color-greyscale-200;
    opacity: 1;
  }
  @include element('image-readmore') {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 9999;
    font-size: 20px;
    color: #FCFCFC;
    i {
      font-size: 12px;
    }
  }
}