@include block('forget-pin') {

  @include element('title') {
    margin-top: rem(24px);
    margin-bottom: rem(40px);
    text-align: center;
    font-weight: 900;
    line-height: rem(42px);
  }
  @include element('sub-title') {
    margin-bottom: rem(8px);
    text-align: center;
    font-weight: 900;
    line-height: rem(29px);
  }
  @include element('description') {
    margin-bottom: rem(24px);
    text-align: center;
    font-weight: 400;
    line-height: rem(20px);
  }
  @include element('action-button') {
    padding: rem(12px) rem(32px);
    font-size: rem(24px);
    line-height: rem(24px);
  }
}
