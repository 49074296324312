@include block('input-autocomplete') {
  font-weight: 300;

  @include element('title') {
    font-size: rem(20px);
    line-height: rem(20px);
    word-break: break-word;
  }

  @include element('input-wrapper') {
    position: relative;
  }

  @include element('input') {
    &:focus,
    &:hover {
      border-color: $--color-violet-400;
      outline: 0 none;
      box-shadow: none;
    }

    @include modifier('error') {
      .ant-select-selector {
        border-color: $--color-red-200 !important;
        caret-color: $--color-red-200;
      }
    }

    .ant-select-selector {
      border: rem(2px) solid $--color-greyscale-400;
      border-radius: rem(4px);
      caret-color: $--color-violet-400;
      min-width: rem(117px);
      height: rem(44px);
      text-indent: $--input-space-left;
      padding: 0;
      font-size: rem(24px);
      outline: 0 none;
      z-index: 0;

      &:focus,
      &:hover {
        border-color: $--color-violet-400;
        outline: 0 none;
        box-shadow: none;
      }

      &[disabled] {
        background: $--color-greyscale-200 !important;
        border: rem(2px) solid $--color-greyscale-200 !important;
        color: $--color-greyscale-400 !important;
      }

      .ant-select-selection-search {
        position: absolute;
        inset: 0;
        input {
          font-weight: 300;
          max-width: 83%;
        }
      }

      .ant-select-selection-placeholder {
        line-height: rem(42px);
        font-size: rem(24px);
        font-weight: 300;
        color: $--color-greyscale-300;
      }
    }
  }

  @include element('icon') {
    position: absolute;
    top: 14%;
    right: 5%;

    @include media-breakpoint-up(md) {
      right: 3%;
    }

    @include media-breakpoint-up(xl) {
      right: 2%;
    }

    i.ps-icon-down {
      font-size: rem(16px);
      font-weight: 300;
      transition: transform .28s;
      transform-origin: center center;
    }

    @include modifier('toggle-up') {
      transform: rotate(-180deg) !important;
    }
  }

  .ant-select-disabled.ant-select .ant-select-selector {
    background: $--color-greyscale-200;
    border: rem(2px) solid $--color-greyscale-200;
    color: $--color-greyscale-400;
  }

  .ant-select-focused.ant-select .ant-select-selector {
    border-color: $--color-violet-400 !important;
    box-shadow: none !important;
  }

  .ant-select-clear {
    font-size: rem(16px);
    position: absolute;
    top: 40%;
    right: 6%;

    @include media-breakpoint-up(md) {
      right: 4%;
    }

    @include media-breakpoint-up(xl) {
      right: 2.5%;
    }
  }
}

@include block('input-message') {
  font-size: rem(20px);
  line-height: rem(20px);
  word-break: break-word;
}

@include block('input-autocomplete-dropdown') {
  box-shadow: 0 rem(4px) rem(8px) rgba(42, 42, 46, 0.15);
  margin-top: -5%;

  .ant-select-item-empty, .ant-select-item-option {
    font-size: rem(20px);
    font-weight: 300;
    line-height: rem(25px);

    &.ant-select-item-option-active,
    &.ant-select-item-option-selected {
      background: $--color-violet-100;
    }
  }
}

@include block('address-autocomplete') {
  font-weight: 300;

  .ps-input-title {
    font-size: rem(18px);
  }

  ::placeholder {
    font-size: rem(24px);
    font-weight: 300;
    color: $--color-greyscale-300;
  }

  .ps-input-autocomplete, .ps-input-layout {
    margin-bottom: rem(15px);
  }
}