@include block("promotions-section") {
  margin-top: 8px;
  .slick-list {
    border-radius: 8px;
    overflow: hidden;
  }
  @include element("header") {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: rem(44px);
    margin-bottom: rem(8px);

    @include media-breakpoint-up(md) {
      height: rem(50px);
    }
  }
  @include element("title") {
    margin-bottom: 0;
    color: $--color-greyscale-600;
    line-height: 1;

    @include media-breakpoint-up(md) {
      font-size: $--font-size-h4-desktop;
    }
  }
  @include element("divider") {
    margin-top: rem(56px);
    margin-bottom: rem(24px);
    background-color: $--color-greyscale-200;
    opacity: 1;
    @include media-breakpoint-up(lg) {
      margin-top: rem(64px);
    }
  }
  @include element("divider-game-register") {
    margin-bottom: rem(0px);
  }
}
