@include block("home-hero"){
  position: relative;
  height: calc(280vh);
  margin-bottom: rem(24px);

  @include media-breakpoint-up("md") {
    height: calc(375vh);
  }
  @include media-breakpoint-up("lg") {
    margin-bottom: calc(24px + 15vw);
  }

  @include element("headline") {
    position: fixed;
    bottom: 0;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: max-content;
    width: max-content;
    margin-bottom: rem(24px);
    z-index: 11;
    font-weight: 900;

    @include media-breakpoint-up("md") {
      margin-bottom: rem(48px);
    }
    @include media-breakpoint-up("lg") {
      margin-bottom: rem(104px);
    }

    @include element("title-hero"){
      display: block;
      color: $--color-greyscale-100;
      text-align: center;
      line-height: rem(60px);
      text-shadow: 2px 2px 2px rgba(42, 42, 46, 0.5);
      white-space: nowrap;
      width: 100vw;
      font-weight: 900;
      margin-bottom: rem(8px);
      @include media-breakpoint-only("xs") {
        font-size: $--font-size-h2-mobile;
        line-height: rem(50px);
      }
      @include media-breakpoint-up("md") {
        font-size: $--font-size-h1-desktop;
        line-height: rem(135.66px);
      }
    }
    @include element("top-1-container"){
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: rem(4px 8px);
      background-color: $--color-primary-2;
      color: $--color-black;
      font-size: rem(28.8px);
      line-height: rem(29px);
      border-radius: $--border-radius-min;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      @include media-breakpoint-up("md") {
        font-size: rem(33.94px);
        line-height: rem(34px);
      }
    }
    @include element("caption"){
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: rem(28.8px);
      line-height: rem(29px);
      color: $--color-greyscale-100;
      margin-left: rem(8px);
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      @include media-breakpoint-up("md") {
        font-size: rem(33.94px);
        line-height: rem(34px);
      }
    }
  }
}

@include block("hero-intro") {
  position: sticky;
  top: 64px;
  left: 0;
  width: 100%;
  height: calc(100vh - 64px);

  @include element("mobile-frame") {
    position: relative;
    width: 100vw;
    height: calc(100vh - 64px);
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);

    &::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      min-width: 360px;
      top: 50%;
      left: 50%;
      transform: translate(-50% , -50%);
      display: block;
      background-image: url("/images/mobile-frame/phone-mobile-cut.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      z-index: 10;
      transform-origin: center center;

      @include media-breakpoint-up("md") {
        background-image: url("/images/mobile-frame/phone-tablet-desktop.png");
      }
      @include media-breakpoint-up("lg") {
        height: calc(100vh + 25vw);
      }
    }

    video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: $--border-radius-1;
      margin: 0;
      padding: 0;
      object-fit: cover;
      object-position: center center;
      z-index: 9;
      // 9 : 16 ratio
      min-width: 64px;
      min-height: 113.78px;
      width: calc(10vh + 12vw);
      height: calc(20vh + 15vw);

      @include media-breakpoint-up("md") {
        // 9 : 16 ratio
        min-width: 64px;
        min-height: 113.78px;
        width:  calc(30vh + 32vw);
        height: calc(20vh + 25vw);
        transform: translate(-49.5%, -51.25%);
      }

      @include media-breakpoint-up("lg") {
        max-width: 980.25px;
        max-height: 725.75px;
        width: calc(36vh + 30vw);
        height: calc(30vh + 20vw);
        transform: translate(-49.5%, -51.25%);
      }
    }
  }

  /** @ignore SEL-1635 - plase ignore this value (because of play/pause is disabled) */
  /*
  // @include element("play-button") {
  //   display: block;
  //   position: absolute;
  //   right: 5vw;
  //   bottom: calc(5vh + 5vw);
  //   z-index: 12;
  //   color: $--color-greyscale-100;
  //   filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  // }
  */
}
