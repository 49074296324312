@include block('image-viewer'){
    position: fixed;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    background-color: rgba(29, 29, 29, 0.9);
    z-index: 300;
    opacity: 0;
    transform: translateX(200%);
  
    @include modifier('active'){
      opacity: 1;
      transform: translateX(0%);
    }
    @include element('frame'){
      position: absolute;
      top: 1.5rem;
      left: 1.5rem;
      right: 1.5rem;
      bottom: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      max-height: 80vh;
      object-fit: contain;
    }
    @include element('close'){
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      background-color: transparent;
      border: none;
      color: white;
    }
  }