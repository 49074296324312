@include block('promotion-article'){
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 24px;
  user-select: none;
  
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  > div {
    margin-bottom: 16px;
    b {
      margin: 0 0.5ch;
      &.first-word {
        margin: 0 0.5ch 0 0;
      }
    }
  }

  @include element('main-title') {
	font-size: rem(34px);
    color: $--color-primary-1;
  }

  h2, h3, h4, h5, h6 {
    display: inline-block;
    padding: 0;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    span {
      color: $--color-primary-1;
    }
  }
  h2 {
    margin-bottom: 16px;
    font-size: 34px;
    font-weight: 900;
    line-height: 35px;
    text-align: left;
  }
  h3 {
    margin-top: 24px;
    display: inline-block;
    color: $--color-primary-1;
    font-weight: 700;
    text-align: left;
  }
  h4 {
    margin-top: 24px;
    display: inline-block;
    color: $--color-greyscale-600;
    font-weight: 700;
    text-align: left;
  }
  b {
    color: $--color-primary-1;
    font-weight: inherit;
  }
  a {
    font-size: inherit;
    font-weight: inherit;
    text-decoration: underline;
    margin: 0 1ch;
  }
  .ant-divider {
    margin: 16px 0;
  }
  @include element('media'){
    margin-bottom: 16px;
	position: relative;
	width: 100%;
  }
  @include element('react-player'){
	width: 100% !important;
	height: 13rem !important;
    @include media-breakpoint-up(md) {
	  height: 25rem !important;
	}

  }

  @include element('title'){
    color: $--color-greyscale-100;
    font-size: rem(41.47px);
    line-height: rem(41.47px);
    font-weight: 900;
    margin: 0;
  }
  @include element('sub-title'){
    font-style: normal;
    font-weight: normal;
    font-size: rem(28.8px);
    line-height: rem(28.8px);
    color: $--color-greyscale-100;
    @include media-breakpoint-up('lg') {
      font-size: rem(33.94px);
      line-height: rem(34px);
    }
  }
  @include element('btn-mt'){
    margin-top: rem(16px);
  }
  @include element('btn-bold'){
    font-weight: bold !important;
  }

  @include element('register-container'){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    max-width: 360px;
    padding: 18px 12px;
    margin: rem(24px) auto 0;
    background-image: url("/images/assets/page-registration-xs-sm.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    border-radius: $--border-radius-2;
    color: $--color-greyscale-100;
    @include media-breakpoint-up('sm') {
      width: rem(308px);
    }   
    @include media-breakpoint-up('md') {
      width: 100%;
      background-image: url("/images/assets/page-registration-md.png");
    }
    @include media-breakpoint-up('lg') {
      background-image: url("/images/assets/page-registration-lg-xl.png");
    }
  }


  @include element('btn-register') {
    margin: auto;
  }
  @include element('section-mt'){
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: rem(36px) auto 0;
  }
  @include element('promo-caption'){
    color: $--color-primary-1;
    margin: 0;

    @include media-breakpoint-up('lg') {
      font-size: rem(67.85px);
      line-height: rem(67.85px);
    }
    @include modifier('blue'){
      color: $--color-lightblue-200;
    }
    @include modifier('green'){
      color: $--color-green-200;
    }
    @include modifier('orange') {
      color: $--orange-400;
    }
  }
  @include element('text'){
    color: $--color-black;
    display: block;
    text-align: center;
    font-size: rem(27.56px);
    line-height: rem(27.56px);
    margin-bottom: 40px;
    width: 100%;

    @include media-breakpoint-up('sm') {
      font-size: rem(34.56px);
      line-height: rem(34.56px);
    }
    @include media-breakpoint-up('md') {
      display: none;
    }
    @include modifier('desktop'){
      display: none;
      @include media-breakpoint-up('md') {
        font-size: rem(47.99px);
        line-height: rem(47.99px);
        display: block;
      }
    }
  }

  @include element('article') {
    display: flex;
    gap: 13px;
    margin-top: 16px;
  }

  @include element("all-article") {
	display: flex;
	justify-content: center;
	width: 100%;
  }

  @include element("to-top-button") {
	float: right;
	margin-right: -10px;
	cursor: pointer;

    @include media-breakpoint-up(md) {
		margin-right: -20px;
    }
    .ant-affix {
      z-index: 20;
    }
  }
}

@include block('contact-tac') {
  padding-bottom: rem(20px);

  @include media-breakpoint-up(lg) {
    padding-top: rem(16px);
    padding-bottom: rem(44px);
  }

  @include element('title') {
    color: $--color-greyscale-600;
    font-weight: bold;
    line-height: 1;
    font-size: $--font-size-h4-mobile;
    @include media-breakpoint-up(md) {
      font-size: $--font-size-h4-desktop;
    }
  }

  @include element('subtitle') {
    display: block;
    margin-top: 0;
    margin-bottom: rem(24px);
    color: $--color-greyscale-600;
    line-height: 1;
    font-size: 26px;
  }

  @include element('link') {
    margin-bottom: rem(8px);

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  @include element('button') {
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
      margin-top: rem(12px);
    }

    .ps-button__body {
      color: $--color-blue-200;
      text-decoration: underline;
    }
  }
}

@include block('partner-list') {
  max-width: 1024px;
  margin: 0 auto 64px;
  h3 {
    width: 100%;
    font-size: 52px;
    font-weight: 800;
    line-height: 2ch;
    color: #be3863;
    text-align: center;
  }
  h4 {
    width: 100%;
    display: block;
    font-weight: 800;
    font-size: 34px;
    line-height: 34px;
    align-items: center;
    color: #2A2A2E;

    @include media-breakpoint-up(md) {
      font-size: 46px;
    }
  }
    
  @include element('list') {
    $--logo-size: 1fr;
    width: 100%;
    display: grid;
    grid-template-columns: $--logo-size $--logo-size $--logo-size;
    justify-content: center;
    align-items: center;
    column-gap: 16px;
    row-gap: 12px;
    @include media-breakpoint-down(md) {
      grid-template-columns: $--logo-size $--logo-size;
    }
  }
  @include element('item') {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    text-align: center;
    padding: 12px 0;
    cursor: pointer;
    aspect-ratio: 1/0.3;
    @include media-breakpoint-down(md) {
      aspect-ratio: 1/0.3;
    }
    
    @include element('img') {
      position: relative;
      margin: auto;
      width: 100%;
      max-width: 160px;
      height: 100%;
      * {
        object-fit: contain;
      }
    }
  }
}

@include block('marquee-section'){
  margin-top: 1rem;
  @include element('btn-bold'){
    font-weight: bold !important;
  }
}

@include block('landing-underline') {
	margin-top: 36px;
	margin-bottom: 36px;
}