@include block('layout'){
  width: 100%;
  min-height: calc(100vh - 360px);
  padding-top: rem(64px);
  @include media-breakpoint-up('sm') {
    min-height: calc(100vh - 375px);
  }
}

@include block("backdrop") {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparentize($color: $--color-greyscale-600, $amount: 0.25);
  overflow: hidden;
}
