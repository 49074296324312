

$--logo-letter-size : 15px;
$--logo-letter-gap-y : 0.5px;
$--logo-letter-y : $--logo-letter-size + ($--logo-letter-gap-y*2);
@include block("animated-logo") {
  height: 26px;
  display: flex;
  align-items: center;
  box-shadow: none;
  transition: filter 0.3s;
  user-select: none;
  cursor: pointer;
  transform: scale(1.4);
  transform-origin: left;
  @media (max-width: 1024px) {
    transform: scale(1.22);
  }
  @include modifier("landing"){
    filter: brightness(0.4) grayscale(1) invert(1);
  }
  @include modifier("default") {
    position: relative;;
    top: 0%;
    left: 0%;
  }
  @include modifier("middle") {
	transform-origin: center;
  }
  
  @include block("animated-logo-pig") {
    display: flex;
    align-items: center;
    img {
      object-fit: contain;
      display: inline-block;
      height: 23px;
      margin-left: 1px;
    }
  }

  @include block("animated-letter") {
    height:  calc($--logo-letter-y * 8) ;
    transform: translateY($--logo-letter-y * -4);
  }

  @keyframes spinLetter {
    from {
      transform: translateY($--logo-letter-y * -4);
    }
    to {
      transform: translateY($--logo-letter-y * -2);
    }
  }

  &:hover {
    @include block("animated-letter") {
      $--duration: 0.7s;
      animation: spinLetter $--duration forwards cubic-bezier(0.42, 0.82, 0.59, 0.99);;
     
      @include modifier("s"){
        animation-duration: $--duration;
      }
      @include modifier("p"){
        animation-duration: $--duration * 0.8;
      }
      @include modifier("i"){
        animation-duration: $--duration * 1.1;
      }
      @include modifier("n"){
        animation-duration: $--duration * 1.3;
      }
      &:nth-child(1) {
        animation-duration: $--duration;
      }
      &:nth-child(2) {
        animation-duration: $--duration * 0.8;
      }
      &:nth-child(3) {
        animation-duration: $--duration * 1.1;
      }
      &:nth-child(4) {
        animation-duration: $--duration * 1.3;
      }
    }
  }

  @include block("animated-letter-spining") {
    animation: spinLetter 0.8s forwards ease-out infinite;
  }
}

@include block("animated-letters") {
  display: flex;
  flex-direction: row;
  height: $--logo-letter-size;
  overflow: hidden;
}
@include block("animated-letter") {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: auto;
  font-size: 0px;
  transition: 0s;
  height: auto;
  .letter {
    display: inline-block;
    height:  $--logo-letter-size ;
    width: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    object-fit: contain;
    margin: $--logo-letter-gap-y 0px;
  }
  @include modifier("s"){
    width: 14px;
    margin-top: 3px;
    .letter {
      margin: $--logo-letter-gap-y 1px;
    }
  }
  @include modifier("p"){
    width: 14px;
    .letter {
      margin: $--logo-letter-gap-y 1px;
    }
  }
  @include modifier("i"){
    width: 5px;
    margin-top: 3px;
    .letter {
      margin: $--logo-letter-gap-y 1px;
    }
  }
  @include modifier("n"){
    width: 14px;
    margin-top: -4px;
    .letter {
      margin: $--logo-letter-gap-y 1px;
    }
  }
  @include modifier("8"){
    width: 14px;
    margin-top: -4px;
    .letter {
      margin: $--logo-letter-gap-y 1px;
    }
  }
}

@include block("minimized-logo") {
  display: flex;
  padding-left: 8px;
  @include element("img") {
    display: flex;
    align-items: center;
    img {
      object-fit: contain;
      display: inline-block;
      height: 29px;
      transform-origin: left;
    }
  }
}





