@include block('deposit-banklist'){
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  @include block('deposit-info'){
    $--default-border-color: $--color-greyscale-200;
    width: calc(100% - 8px);
    background: white;
    margin: 0 4px 24px;
    border: 2px solid $--default-border-color;
    border-radius: 4px;

    &.flasher {
      &.flashing {
        border-color: $--color-primary-1;
        animation: flashing .6s 0.2s forwards ease-out;
        @keyframes flashing {
          from {
            border-color: $--color-primary-1;
          }
          to {
            border: 2px solid $--default-border-color;
          }
        }
      }
    }
    @include element('texts'){
      width: 64%;
      margin: 16px auto 8px;
      min-height: 8ch;
      color: $--color-greyscale-600;
      @include element('text'){
        
      }
    }
    @include element('btn-container'){
      display: flex;
      justify-content: center;

      // THIS : COPY BORDER ANIMATION
      button {
        &.flashing {
          border-color: $--color-primary-1;
          color: $--color-primary-1;
          animation: flashing .6s 0.2s forwards ease-out;
          @keyframes flashing {
            from {
              border-color: $--color-primary-1;
              color: $--color-primary-1;
            }
            to {
              border: 2px solid $--default-border-color;
              color: $--color-greyscale-600;
            }
          }
        }
      }
    }
    @include element('cover'){
      width: 100%;
      height: 100%;
      border-radius: 4px;
      overflow: hidden;
    }
  }
  @include element('condition-text'){
    width: 100%;
    color: $--color-greyscale-600;
  }
}
