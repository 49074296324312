@include block("user-info") {
  border-radius: $--border-radius-1;
  box-shadow: $--box-shadow-3;
  padding: rem(16px 24px);
  font-size: $--font-size-b1;
  line-height: 1;
  background-color: $--color-violet-100;

  @include element("section") {
    width: 100%;

    &:first-child {
      margin-bottom: 1rem;
    }
  }

  @include element("table") {
    table-layout: fixed;
    caption-side: top;
    width: 100%;

    th,
    td {
      vertical-align: top;
      font-size: 1rem;
      line-height: 1rem;
      padding-bottom: rem(8px);
    }

    th {
      color: $--color-greyscale-500;
      font-weight: normal;
      width: 60%;
    }

    td {
      color: $--color-greyscale-600;
      text-transform: uppercase;
    }

    img,
    span {
      display: inline-block;
      vertical-align: middle;
    }

    img {
      width: rem(30px);
      height: rem(30px);
      margin-right: rem(8px);
    }

    span {
      line-height: rem(30px);
    }
  }

  @include element("caption") {
    caption-side: top;
    padding-top: 0;
    padding-bottom: rem(8px);
    color: $--color-greyscale-600;
    font-size: 1rem;
    line-height: 1;
  }
}
