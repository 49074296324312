@include block('maintenance') {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: calc(100vh - 128px); // Note: 128px is calculate from header height * 2

  @include element('logo') {
    display: block;
    margin: rem(16px) auto 0 auto;
  }
  @include element('logo-container') {
    text-align: center;
    img {
      margin-top: rem(16px);
      margin-left: 5%;
    }
  }
  @include element('title') {
    display: block;
    margin-top: rem(16px);
    font-weight: 400;
    color: $--color-violet-400;
    text-align: center;
  }
  @include element('content') {
    text-align: center;
    line-height: rem(24px);
    padding: 0 rem(12px);

    @include media-breakpoint-up(md) {
      padding: 0 16.5%; // Note: 8 grid = (8/12 * 100)/2
    }
    @include media-breakpoint-up(lg) {
      padding: 0 25%; // Note: 6 grid = (6/12 * 100)/2
    }
  }
}